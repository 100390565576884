import React, { useState } from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import useTopScroll from "hooks/useTopScroll";

import styles from "./HowTo.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const HowTo = () => {
  useTopScroll();
  const { how_to_page } = usePrismicContent({
    prismicDocTypes: [docType.how_to_page],
  });

  return (
    <div className={styles.container}>
      {!how_to_page && <LoadingPage />}
      {how_to_page && (
        <>
          <div className={styles.content}>
            <span className={styles.aboutTitle}>
              <span className={textStyles.largeTitleDark}>
                {RichText.asText(how_to_page.title)}
              </span>
            </span>
            <PrismicText prismicText={how_to_page.body} />
          </div>
        </>
      )}
    </div>
  );
};

export default HowTo;
