import React from "react";
import styles from "./FormBlock.module.css";

const FormBlock = (props) => {
  const {
    children,
    name,
    label,
    sublabel,
    required,
    requiredLabel,
    optionalLabel,
    error,
    touched,
    hasValue,
    maxLength,
    value,
    key,
  } = props;
  const hasRequiredError = required && touched && !hasValue;

  return (
    <div
      key={key}
      className={`${styles.formBlock} ${
        hasRequiredError ? styles.requiredErrorContainer : ""
      }`}
    >
      <div className={styles.formLabel}>
        <label htmlFor={name}>{label}</label>
        <span className={styles.sublabel}>{sublabel}</span>
      </div>
      {children}
      {required && (
        <span
          className={`${styles.required} ${
            hasRequiredError ? styles.requiredError : ""
          }`}
        >
          {requiredLabel}
        </span>
      )}
      {!required && optionalLabel && (
        <span className={`${styles.required}`}>{optionalLabel}</span>
      )}
      {maxLength && (
        <span
          className={`${styles.maxLength} ${
            value.length > maxLength ? styles.maxLengthError : ""
          }`}
        >
          {value.length} / {maxLength}
        </span>
      )}

      {error && touched && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default FormBlock;
