import React, { useState } from "react";

import { RichText } from "prismic-reactjs";
import PrismicText from "components/PrismicText/PrismicText";

import styles from "./ClimateKnowledgeBlock.module.css";

const ClimateKnowledgeBlock = ({ sectionDoc }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles.section}>
      <div
        className={styles.dropdownTitle}
        onClick={() => setIsActive(!isActive)}
      >
        <h4>{RichText.asText(sectionDoc.section_title)}</h4>
        <div
          className={isActive ? styles.dropdownMinus : styles.dropdownPlus}
        />
      </div>
      <div
        className={`${styles.dropdownBody} ${
          isActive ? styles.active : styles.inactive
        }`}
      >
        <PrismicText prismicText={sectionDoc.section_body} />
      </div>
    </div>
  );
};

export default ClimateKnowledgeBlock;
