import { useEffect } from "react";

const useTopScroll = () => {
  useEffect(() => {
    document.getElementById("container").scrollTo({
      behavior: "auto",
      top: 0,
    });
  }, []);

  return true;
};

export default useTopScroll;
