import React, { useContext } from "react";
import Select from "react-select";
import { docType } from "utils/prismic";
import { usePrismicContent } from "components/PrismicText/PrismicText";
import { LanguageContext } from "contexts/language/language";
import { defaultLanguageSelectOption } from "contexts/language/languageConstants";
import styles from "./LanguageSelector.module.css";

const LanguageSelector = () => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  let selectedLanguage = defaultLanguageSelectOption;

  const { form_language_list } = usePrismicContent({
    prismicDocTypes: [docType.form_language_list],
  });

  // Format the language options
  const languageOptions = form_language_list.languages.map(
    ({ language_code, language_label }) => {
      const languageOption = { label: language_label, value: language_code };

      // If the language option is also the currently-selected language, show it as the default
      if (language_code === userLanguage) {
        selectedLanguage = languageOption;
      }

      return languageOption;
    }
  );

  // set selected language by calling context method
  const handleLanguageChange = (value) => userLanguageChange(value);

  if (languageOptions.length <= 1) return null;

  return (
    <Select
      name="language"
      defaultValue={selectedLanguage}
      options={languageOptions}
      onChange={(newLanguage) => handleLanguageChange(newLanguage.value)}
      className={styles.select}
    />
  );
};

export default LanguageSelector;
