import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "contexts/user";
import LanguagePicker from "components/header/LanguagePicker/LanguagePicker";

import { docType } from "utils/prismic";

import { firebaseAuth } from "firebase/config";
import FeatureFlagMenu from "components/FeatureFlagMenu/FeatureFlagMenu";
import { isCurrentUserAdmin } from "contexts/user";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const Navigation = (props) => {
  const {
    navigationItemClass,
    navigationSubListClass,
    languagePickerClass,
    onLinkClick,
  } = props;

  const [canSeeFFMenu, setCanSeeFFMenu] = useState(false);
  const userContext = useContext(UserContext);
  useEffect(() => {
    isCurrentUserAdmin((isAdmin) => setCanSeeFFMenu(isAdmin));
  }, [userContext.user]);

  // Get copy
  const { navigation } = usePrismicContent({
    prismicDocTypes: [docType.navigation],
  });

  const onLogOutClick = () => {
    firebaseAuth.signOut().then(() => {});
  };

  return (
    <>
      {navigation && (
        <>
          <div className={navigationItemClass}>
            <Link to="/map" onClick={onLinkClick}>
              {navigation.map}
            </Link>
          </div>
          <div className={navigationItemClass}>
            {navigation.about}
            <div className={navigationSubListClass}>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: "/how",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.how_it_works}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/whyConversations",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.why_conversations_matter}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/team",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.who_we_are}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/partners",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.partners}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/faq",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.faq}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/contact",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.contact_us}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/legalStuff",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.privacy_policy}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={navigationItemClass}>
            {navigation.having_conversations}
            <div className={navigationSubListClass}>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: "/howTo",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.how_to_have}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/advice",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.conversation_advice}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/starters",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.conversation_starters}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/knowledge",
                    }}
                    onClick={onLinkClick}
                  >
                    {navigation.climate_knowledge}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className={`${navigationItemClass} navigationHighlight`}>
            <Link to="/form" onClick={onLinkClick}>
              {navigation.form}
            </Link>
          </div>

          {userContext.user && (
            <div className={navigationItemClass}>
              <Link to={`/user/${userContext.user.uid}`} onClick={onLinkClick}>
                {userContext.user.displayName
                  ? userContext.user.displayName
                  : userContext.user.email}
              </Link>
              <div className={navigationSubListClass}>
                <ul>
                  {canSeeFFMenu && (
                    <li>
                      <FeatureFlagMenu />
                    </li>
                  )}
                  <li>
                    <Link
                      to={`/user/${userContext.user.uid}`}
                      onClick={onLinkClick}
                    >
                      {navigation.user_conversations}
                    </Link>
                  </li>
                  <li>
                    <Link to={"/signUp"} onClick={onLogOutClick}>
                      {navigation.log_out}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {!userContext.user && (
            <div className={navigationItemClass}>
              <Link to="/signUp" onClick={onLinkClick}>
                {navigation.sign_in_register}
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Navigation;
