import React, { useState, useEffect, useLayoutEffect } from "react";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ConversationPopupBody from "components/map/ConversationPopup/ConversationPopupBody";
import ConversationPopupChoiceBlock from "components/map/ConversationPopup/ConversationPopupChoiceBlock";
import { mediaQueryListener } from "utils/media-query";
import { projectDatabase, acceptedConversationsDir } from "firebase/config";

import styles from "./ConversationPopup.module.css";

const singleColumnMaxWidth = 800;

const ConversationPopup = ({
  features,
  preloadedConversationId,
  onChooseConversationId,
  onFinishLoad,
  localizedDoc,
}) => {
  const [conversationData, setConversationData] = useState(null);
  const [conversationIdChosen, setConversationIdChosen] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const [isSingleColumn, setIsSingleColumn] = useState(
    window.innerWidth < singleColumnMaxWidth
  );

  const loadConversation = (conversationId) => {
    projectDatabase
      .ref(`/${acceptedConversationsDir}/${conversationId}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setConversationData(data);
          if (onFinishLoad) {
            onFinishLoad(conversationId, data);
          }
        }
      })
      .catch((error) => {
        console.error(
          `Error: could not find conversation with id ${conversationId}... ${error}`
        );
      });
  };

  useEffect(() => {
    return mediaQueryListener(singleColumnMaxWidth, (isUnderMaxWidth) => {
      setIsSingleColumn(isUnderMaxWidth);
    });
  }, []);

  useLayoutEffect(() => {
    const onResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    if (features && features.length === 1) {
      setConversationIdChosen(true);
      loadConversation(features[0].properties.uid);
      onChooseConversationId(features[0].properties.uid);
    } else if (preloadedConversationId) {
      setConversationIdChosen(true);
      loadConversation(preloadedConversationId);
      onChooseConversationId(preloadedConversationId);
    }
  }, []);

  return (
    <div
      className={
        styles.conversationPopup +
        (conversationData ? "" : " " + styles.loadingContainer)
      }
    >
      {!conversationIdChosen && features && features.length > 1 && (
        <div style={{ overflowY: "auto" }}>
          {features.map((feature, index) => {
            return (
              <React.Fragment key={`popup-choice-${feature.properties.uid}`}>
                <ConversationPopupChoiceBlock
                  preview={feature.properties.preview}
                  onClick={() => {
                    loadConversation(feature.properties.uid);
                    setConversationIdChosen(true);
                    onChooseConversationId(feature.properties.uid);
                  }}
                  key={index}
                />
                {index < features.length - 1 && (
                  <div className={styles.choiceDivider} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
      {conversationIdChosen && conversationData && localizedDoc && (
        <ConversationPopupBody
          conversationData={conversationData}
          tags={conversationData.tags}
          windowHeight={windowHeight}
          isSingleColumn={isSingleColumn}
          localizedDoc={localizedDoc}
        />
      )}
      {conversationIdChosen && (!conversationData || !localizedDoc) && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default ConversationPopup;
