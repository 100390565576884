import React from "react";
import Select from "react-select";

import styles from "./FormDropdown.module.css";

const FormDropdown = ({
  placeholder,
  localizedOptions,
  onSelect,
  onBlur,
  idKey,
  textKey,
  defaultValueId,
}) => {
  let defaultValue = null;
  const selectOptions = localizedOptions.map((option) => {
    const label = option[textKey];
    const value = option[idKey];
    const selectOption = { label, value };

    // If default ID is given, find the right option for it
    if (defaultValueId === value) {
      defaultValue = selectOption;
    }

    return selectOption;
  });

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused
        ? "rgba(0, 0, 0, 0.06)"
        : isSelected
        ? "rgba(0, 0, 0, 0.2)"
        : "transparent",
      color: "hsl(0, 0%, 20%)",
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid #292929",
      backgroundColor: "transparent",
      borderRadius: "0",
    }),
  };

  return (
    <Select
      options={selectOptions}
      placeholder={placeholder}
      defaultValue={defaultValue}
      styles={customStyles}
      className={styles.select}
      onChange={(newValue) => onSelect && onSelect(newValue.value)}
      onBlur={onBlur}
    />
  );
};

export default FormDropdown;
