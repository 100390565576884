import React from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import useTopScroll from "hooks/useTopScroll";

import styles from "./Team.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const Team = () => {
  useTopScroll();
  const { about_page } = usePrismicContent({
    prismicDocTypes: [docType.team_page],
  });

  return (
    <div className={styles.container}>
      {!about_page && <LoadingPage />}
      {about_page && (
        <div className={styles.content}>
          <span className={textStyles.largeTitleDark}>
            {RichText.asText(about_page.title)}
          </span>
          <PrismicText prismicText={about_page.intro} />

          <div className={styles.team}>
            {about_page.team_block.map((block) => {
              return (
                <div className={styles.teamBlock}>
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${
                        block.image && block.image.url ? block.image.url : ""
                      })`,
                    }}
                  />

                  <PrismicText prismicText={block.name} />
                  <PrismicText prismicText={block.title} />
                </div>
              );
            })}
          </div>

          <PrismicText prismicText={about_page.outro} />
        </div>
      )}
    </div>
  );
};

export default Team;
