import React from "react";

import styles from "./SocialLinkButtons.module.css";

// TODO: add in real share stuff
const twitterURL = "https://twitter.com/climatechats";
const facebookURL = "https://www.facebook.com/";
const instagramURL = "https://www.instagram.com/climatechats_/";

const TwitterLinkButton = (props) => {
  return (
    <a href={twitterURL} target="_blank" rel="noreferrer">
      <div className={`${styles.button} ${styles.twitterIcon}`} />
    </a>
  );
};

const FacebookLinkButton = (props) => {
  return (
    <a href={facebookURL} target="_blank" rel="noreferrer">
      <div className={`${styles.button} ${styles.facebookIcon}`} />
    </a>
  );
};

const InstagramLinkButton = (props) => {
  return (
    <a href={instagramURL} target="_blank" rel="noreferrer">
      <div className={`${styles.button} ${styles.instagramIcon}`} />
    </a>
  );
};

export { TwitterLinkButton, FacebookLinkButton, InstagramLinkButton };
