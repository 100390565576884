import React, { useState, createContext, useContext, useEffect } from "react";

import {
  queryMultiplePrismicDocumentTypes,
  prismicDictionary,
  docType,
} from "utils/prismic";

import { defaultLanguage } from "./languageConstants";

export const LanguageContext = createContext({
  loading: true,
  userLanguagePrismic: defaultLanguage,
  dictionary: { [defaultLanguage]: {} },
});

export function LanguageProvider({ children }) {
  // const defaultUserLanguage = window.localStorage.getItem("rcml-lang");
  const [loading, setLoading] = useState(true);
  const [userLanguagePrismic, setUserLanguagePrismic] = useState(
    defaultLanguage
  );
  const [dictionary, setDictionary] = useState({ [defaultLanguage]: {} });

  const provider = {
    loading,
    userLanguagePrismic,
    userLanguageChange: (selected) => {
      setUserLanguagePrismic(selected);
      window.localStorage.setItem("rcml-lang", selected);
    },
    dictionary,
  };

  useEffect(() => {
    setLoading(true);

    const newDictionary = { ...dictionary, [userLanguagePrismic]: {} };

    queryMultiplePrismicDocumentTypes(
      userLanguagePrismic,
      Object.values(docType)
    )
      .then((res) => {
        for (let result of res.results) {
          if (result.type === docType.conversation_tags) {
            const tagRefObj = getConversationTagReferenceObject(
              result.data.conversation_tags_key
            );
            newDictionary[userLanguagePrismic][result.type] = tagRefObj;
            continue;
          }

          if (result.uid) {
            if (!newDictionary[userLanguagePrismic][result.type])
              newDictionary[userLanguagePrismic][result.type] = {};

            newDictionary[userLanguagePrismic][result.type][result.uid] =
              result.data;
          } else {
            newDictionary[userLanguagePrismic][result.type] = result.data;
          }
        }
        setDictionary(newDictionary);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error: couldn't query prismic - ", err);
        setLoading(false);
      });
  }, [userLanguagePrismic]); // Can't include dictionary!

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}

const getConversationTagReferenceObject = (conversation_tags_key) => {
  const refObj = {};
  conversation_tags_key.map((tag) => {
    refObj[tag.tag_id] = tag.tag_text;
  });
  return refObj;
};

const LocalizedTag = ({ tagId, className }) => {
  const context = useContext(LanguageContext);

  const languageDoc = prismicDictionary[context.userLanguagePrismic] || prismicDictionary[defaultLanguage];
  const tagReference = languageDoc[docType.conversation_tags];
  const tagText = tagReference
    ? tagReference[tagId]
    : tagId.split("_").join(" ");

  return <span className={className}>{tagText || tagId}</span>;
};

export { LocalizedTag };
