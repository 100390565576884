import { useEffect, useState } from "react";
import { projectDatabase } from "firebase/config";
import { acceptedConversationsDir } from "firebase/config";

const useLoadAcceptedConversations = () => {
  const [acceptedConversations, setAcceptedConversations] = useState({});
  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    projectDatabase
      .ref(`/${acceptedConversationsDir}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setAcceptedConversations(data);
          setFinishedLoading(true);
        }
      })
      .catch((error) => {
        console.error(
          `Error: Could not load conversations from Firestore: ${error}`
        );
      });
  }, [setAcceptedConversations]);

  return { acceptedConversations, finishedLoading };
};

export default useLoadAcceptedConversations;
