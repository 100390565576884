import React from "react";
import styles from "./ConversationPreview.module.css";
import { LocalizedTag } from "contexts/language/language";
import { Link } from "react-router-dom";

const ConversationPreview = ({ conversationData, isOnMap }) => {
  const preview = (
    <div className={styles.conversationBlock}>
      <div className={styles.conversationPreview}>
        <span className={styles.location}>
          {conversationData.locationString}
        </span>
        <span className={styles.description}>
          {conversationData.description}
        </span>
        {conversationData.tags && conversationData.tags.length > 0 && (
          <div className={styles.contentRow}>
            {conversationData.tags.map((tag, i) => {
              return (
                <LocalizedTag tagId={tag} className={styles.tag} key={i} />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );

  return isOnMap ? (
    <Link to={`/map/${conversationData.conversationId}`}>{preview}</Link>
  ) : (
    preview
  );
};

export default ConversationPreview;
