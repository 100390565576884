import React from "react";

import styles from "./MenuButton.module.css";

const MenuButton = (props) => {
  const { showCloseButton, onClick } = props;

  return (
    <div
      className={`${styles.menuButton} ${showCloseButton ? styles.active : ""}`}
      onClick={onClick}
    >
      <div className={styles.b1}></div>
      <div className={styles.b2}></div>
      <div className={styles.b3}></div>
    </div>
  );
};

export default MenuButton;
