import React from "react";
import { Link } from "react-router-dom";

import styles from "./Button.module.css";

const Button = (props) => {
  const { highlight, error, linkTo, onClick, theme } = props;

  const accentTheme = theme ? styles.blackKelsonAccent : "";
  const normalTheme = theme ? styles.blackKelson : "";

  let innerButton = (
    <div
      className={`${styles.button} ${highlight ? styles.highlight : ""} ${
        error ? styles.error : ""
      } ${highlight ? accentTheme : normalTheme}`}
      tabIndex="1"
      onClick={onClick}
    >
      {props.children}
    </div>
  );

  if (linkTo) {
    innerButton = <Link to={linkTo}>{innerButton}</Link>;
  }

  return <div className={styles.buttonContainer}>{innerButton}</div>;
};

export default Button;
