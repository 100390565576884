import React, { useState, useContext } from "react";
import { Formik } from "formik";
import FormBlock from "components/form/FormBlock/FormBlock";
import FormSubmit from "components/form/FormSubmit/FormSubmit";
import { UserContext } from "contexts/user";
import { initialValues, fieldValueEmptyCheckers } from "utils/data-utils";
import { MBs } from "utils/file-utils";
import FileUpload from "components/form/FileUpload/FileUpload";
import FormLocationPicker from "components/form/FormLocationPicker/FormLocationPicker";
import FormTagger from "components/form/FormTagger/FormTagger";
import FormDropdown from "components/form/FormDropdown/FormDropdown";
import ReactTooltip from "react-tooltip";

import ReCAPTCHA from "react-google-recaptcha";

import styles from "./ConversationForm.module.css";
import { RichText } from "prismic-reactjs";
import PrismicText from "components/PrismicText/PrismicText";

const maxCharsShort = 50;
const maxCharsMed = 500;
const maxCharsLong = 1000;
const types = ["image/png", "image/jpeg"];
const CAMPAIGN_FIELD_ID = "campaign";
const DEFAULT_CAMPAIGN = "independent";

const inputTypes = {
  one_line_input: "one_line_input",
  multiline_input: "multiline_input",
  tagger: "tagger",
  location_input: "location_input",
  checkbox: "checkbox",
  photo_input: "photo_input",
  social_media_input: "social_media_input",
  radio_input: "radio_input",
  relationship_dropdown: "relationship_dropdown",
};

const possibleInvalidErrors = {};

const ageConsent = {
  ADULT: "adult",
  MINOR: "minor",
};

const valueExists = (fieldId, value) => {
  return (
    value !== null &&
    value !== undefined &&
    !fieldValueEmptyCheckers[fieldId](value)
  );
};

const ConversationForm = ({
  onSubmitData,
  isSubmitting,
  localizedDoc,
  initialCampaign,
}) => {
  const [requiredElementTouched, setRequiredElementTouched] = useState({});
  const [photoError, setPhotoError] = useState(null);
  const [
    FORCE_RERENDER_THIS_SUCKS_MUST_CHANGE,
    setFORCE_RERENDER_THIS_SUCKS_MUST_CHANGE,
  ] = useState(0);
  const userContext = useContext(UserContext);

  const findPhotoFileError = (file) => {
    if (file && !types.includes(file.type)) {
      return possibleInvalidErrors.photo;
    } else if (file && file.size > MBs(10)) {
      // Disallow files of size >10MB
      return RichText.asText(localizedDoc.photo_file_size_error);
    }
    return null;
  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (values.email && !regex.test(values.email)) {
      errors.email = possibleInvalidErrors.email;
    }

    setPhotoError(findPhotoFileError(values.photo));

    if (values.location && !values.location.center) {
      errors.location = possibleInvalidErrors.location;
    }

    if (
      !values.first_name ||
      !values.email ||
      !values.partner_relationship ||
      !values.partner ||
      !values.location ||
      !values.description ||
      !values.terms_agreement ||
      !values.age_agreement ||
      !values.recaptcha ||
      !valueExists("tags", values.tags)
    ) {
      errors.required = RichText.asText(localizedDoc.required_label);
    }

    return errors;
  };

  const initialFormValues = initialValues({
    initialCampaign: initialCampaign || DEFAULT_CAMPAIGN,
  });
  const shouldUseUserEmail =
    userContext.user &&
    userContext.user.emailVerified &&
    userContext.user.email;
  const userEmail = shouldUseUserEmail ? userContext.user.email : null;
  if (shouldUseUserEmail) {
    initialFormValues.email = userEmail;
  }

  const formFields = localizedDoc.form_questions.form_fields;

  return (
    <div className={`${styles.form} ${isSubmitting ? styles.inactive : ""}`}>
      {localizedDoc && (
        <>
          <div className={styles.accountInfo}>
            {userContext.user ? (
              <>
                <PrismicText
                  prismicText={localizedDoc.account_lead_in_signed_in}
                />
              </>
            ) : (
              <>
                <PrismicText
                  prismicText={localizedDoc.account_lead_in_signed_out}
                />
              </>
            )}
          </div>

          <Formik
            initialValues={initialFormValues}
            validate={validate}
            onSubmit={onSubmitData}
          >
            {(formik) => {
              const {
                values,
                handleChange,
                handleSubmit,
                errors,
                touched,
                handleBlur,
                isValid,
                dirty,
                setFieldValue,
              } = formik;

              return (
                <form onSubmit={handleSubmit}>
                  <FormBlock
                    name={CAMPAIGN_FIELD_ID}
                    label={
                      <PrismicText
                        prismicText={localizedDoc.campaign_dropdown_label}
                      />
                    }
                    touched={touched[CAMPAIGN_FIELD_ID]}
                    hasValue={valueExists(
                      CAMPAIGN_FIELD_ID,
                      values[CAMPAIGN_FIELD_ID]
                    )}
                    required={true}
                    value={values[CAMPAIGN_FIELD_ID]}
                  >
                    <FormDropdown
                      localizedOptions={localizedDoc.campaign_list || []}
                      onSelect={(campaignId) => {
                        setFieldValue(CAMPAIGN_FIELD_ID, campaignId);
                      }}
                      onBlur={() => {
                        touched[CAMPAIGN_FIELD_ID] = true;
                      }}
                      idKey="campaign_id"
                      textKey="name"
                      defaultValueId={initialCampaign || DEFAULT_CAMPAIGN}
                    />
                  </FormBlock>

                  {formFields.map((field) => {
                    const fieldId = field.field_id;
                    let label = field.label[0] ? (
                      <PrismicText prismicText={field.label} />
                    ) : null;
                    let sublabel = field.sublabel[0] ? (
                      <PrismicText prismicText={field.sublabel} />
                    ) : null;
                    const placeholder = field.placeholder[0]
                      ? field.placeholder[0].text
                      : null;
                    let inputTouched = touched[fieldId];
                    let optional = null;
                    let maxLength = null;

                    if (field.invalid_error) {
                      possibleInvalidErrors[fieldId] = RichText.asText(
                        field.invalid_error
                      );
                    }

                    let input;
                    if (field.input_type === inputTypes.one_line_input) {
                      let onChange = handleChange;
                      let onBlur = handleBlur;
                      let value = values[fieldId];
                      let className =
                        errors.name && touched.name ? styles.inputError : null;
                      let readonly = false;

                      // Special attributes for email
                      if (fieldId === "email") {
                        sublabel = (
                          <span
                            className={styles.hoverTooltip}
                            data-tip
                            data-for="emailWhy"
                          >
                            <i>
                              <PrismicText
                                prismicText={localizedDoc.email_hover_sublabel}
                              />
                            </i>
                            <ReactTooltip
                              id="emailWhy"
                              className={styles.emailTooltip}
                            >
                              <span>
                                <PrismicText
                                  prismicText={localizedDoc.email_hover_content}
                                />
                              </span>
                            </ReactTooltip>
                          </span>
                        );
                        value = shouldUseUserEmail ? userEmail : values.email;
                        onChange = shouldUseUserEmail
                          ? (e) => setFieldValue("email", userEmail)
                          : handleChange;
                        onBlur = shouldUseUserEmail
                          ? (e) => setFieldValue("email", userEmail)
                          : handleBlur;
                        className = `${
                          errors.email && touched.email
                            ? styles.inputError
                            : null
                        } ${shouldUseUserEmail ? styles.readonly : ""}`;
                        readonly = shouldUseUserEmail;
                      }

                      input = (
                        <input
                          type="text"
                          name={fieldId}
                          id={fieldId}
                          placeholder={placeholder}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          maxLength={maxCharsShort}
                          className={className}
                          readOnly={readonly}
                        />
                      );
                    }

                    if (field.input_type === inputTypes.multiline_input) {
                      maxLength =
                        fieldId === "description" ? maxCharsLong : maxCharsMed;
                      input = (
                        <textarea
                          name={fieldId}
                          id={fieldId}
                          rows="10"
                          cols="30"
                          value={values[fieldId]}
                          placeholder={placeholder}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={maxLength}
                          className={
                            errors[fieldId] && touched[fieldId]
                              ? styles.inputError
                              : null
                          }
                        />
                      );
                    }

                    if (field.input_type === inputTypes.photo_input) {
                      input = (
                        <FileUpload
                          onFindError={findPhotoFileError}
                          onSetFile={(file) => {
                            setFieldValue(fieldId, file);
                          }}
                          fileUploadLabels={{
                            input_text: localizedDoc.photo_input_text,
                            input_select_button:
                              localizedDoc.photo_input_select_button,
                            input_remove_button:
                              localizedDoc.photo_input_remove_button,
                          }}
                          disabled={values.age_agreement === ageConsent.MINOR}
                        />
                      );
                    }

                    if (field.input_type === inputTypes.location_input) {
                      input = (
                        <FormLocationPicker
                          placeholderLocation={placeholder}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onSetLocation={(locationObject) => {
                            setFieldValue(fieldId, locationObject);
                          }}
                        />
                      );
                    }

                    if (field.input_type === inputTypes.tagger) {
                      inputTouched = requiredElementTouched[fieldId];
                      input = (
                        <FormTagger
                          onChangeTags={(tags) => {
                            setFieldValue(fieldId, tags);
                            //TODO: destroy this
                            setFORCE_RERENDER_THIS_SUCKS_MUST_CHANGE(
                              FORCE_RERENDER_THIS_SUCKS_MUST_CHANGE + 1
                            );
                          }}
                          onTouched={() => {
                            if (!field.required) {
                              return;
                            }
                            const newRequiredElementTouched =
                              requiredElementTouched;
                            newRequiredElementTouched[fieldId] = true;
                            setRequiredElementTouched(
                              newRequiredElementTouched
                            );

                            //TODO: destroy this
                            setFORCE_RERENDER_THIS_SUCKS_MUST_CHANGE(
                              FORCE_RERENDER_THIS_SUCKS_MUST_CHANGE + 1
                            );
                          }}
                          localizedTags={localizedDoc.conversation_tags}
                        />
                      );
                    }

                    if (field.input_type === inputTypes.relationship_dropdown) {
                      const relationshipDropdownOptions =
                        localizedDoc.relationships;
                      input = (
                        <FormDropdown
                          localizedOptions={relationshipDropdownOptions}
                          placeholder={RichText.asText(field.placeholder)}
                          onSelect={(relationshipId) => {
                            setFieldValue(fieldId, relationshipId);
                          }}
                          onBlur={() => {
                            touched[fieldId] = true;
                          }}
                          idKey="relationship_id"
                          textKey="relationship_text"
                        />
                      );
                    }

                    if (field.input_type === inputTypes.checkbox) {
                      inputTouched = requiredElementTouched[fieldId];
                      const checkboxLabel = label;
                      label = null;
                      optional = field.required
                        ? null
                        : localizedDoc.optional_label;
                      input = (
                        <div
                          className={`${styles.checkbox} ${
                            field.required && inputTouched && !values[fieldId]
                              ? styles.checkboxError
                              : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            id={fieldId}
                            name={fieldId}
                            onClick={(e) => {
                              setFieldValue(fieldId, e.target.checked);

                              if (field.required) {
                                const newRequiredElementTouched =
                                  requiredElementTouched;
                                newRequiredElementTouched[fieldId] = true;
                                setRequiredElementTouched(
                                  newRequiredElementTouched
                                );
                              }
                            }}
                          />
                          <label htmlFor={fieldId}>{checkboxLabel}</label>
                        </div>
                      );
                    }

                    if (field.input_type === inputTypes.radio_input) {
                      label = null;
                      input = (
                        <div className={styles.radioInput}>
                          {field.label.map((option, index) => {
                            return (
                              <div className={styles.radioOption} key={index}>
                                <input
                                  type="radio"
                                  id={fieldId}
                                  name={fieldId}
                                  onClick={(e) => {
                                    setFieldValue(fieldId, e.target.value);
                                  }}
                                  value={option.text.split(" || ")[0]}
                                />
                                <label htmlFor={fieldId}>
                                  {option.text.split(" || ")[1]}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                    return (
                      <FormBlock
                        key={fieldId}
                        name={fieldId}
                        label={label}
                        sublabel={sublabel}
                        error={errors[fieldId]}
                        touched={inputTouched}
                        hasValue={valueExists(fieldId, values[fieldId])}
                        required={field.required}
                        requiredLabel={RichText.asText(
                          localizedDoc.required_label
                        )}
                        optionalLabel={optional}
                        maxLength={maxLength}
                        value={values[fieldId]}
                      >
                        {input}
                        {fieldId === "photo" && photoError && (
                          <span className={styles.error}>{photoError}</span>
                        )}
                      </FormBlock>
                    );
                  })}

                  <FormBlock
                    requiredLabel={RichText.asText(localizedDoc.required_label)}
                    required
                  >
                    <div className={styles.recaptcha}>
                      <ReCAPTCHA
                        sitekey="6LcIuwobAAAAANI8c2F8Xqfy1ZsG9sHZ8Za5vId9"
                        onChange={(recaptcha) =>
                          setFieldValue("recaptcha", recaptcha)
                        }
                      />
                    </div>
                  </FormBlock>

                  <FormSubmit
                    dirty={dirty}
                    isValid={isValid}
                    label={RichText.asText(localizedDoc.submit_button_label)}
                  />
                  {!isValid ? (
                    <div className={styles.error}>
                      {RichText.asText(localizedDoc.submit_error)}
                    </div>
                  ) : null}
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
};

export default ConversationForm;
