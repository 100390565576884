import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { LocalizedTag } from "contexts/language/language";
import PrismicText from "components/PrismicText/PrismicText";

import styles from "./LandingConversation.module.css";

const LandingConversation = ({ localizedDoc, theme }) => {
  const history = useHistory();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (localizedDoc.conversation_tags) {
      setTags(
        localizedDoc.conversation_tags.map(
          (tagContainer) => tagContainer.tag[0].text
        )
      );
    }
  }, [localizedDoc]);

  const kelson = theme ? styles.kelson : "";

  return (
    <div
      className={`${styles.landingConversationContainer} ${styles.link}`}
      onClick={() =>
        localizedDoc &&
        history.push(`/map/${localizedDoc.conversation_id[0].text}`)
      }
    >
      <div
        className={
          styles.landingConversation +
          (localizedDoc ? "" : " " + styles.loadingContainer)
        }
      >
        {localizedDoc ? (
          <div className={styles.content}>
            {localizedDoc.conversation_photo && (
              <div className={styles.imageContainer}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${localizedDoc.conversation_photo.url})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            )}
            <h1 className={`${theme ? "kelson" : ""}`}>
              {localizedDoc.conversation_location[0].text}
            </h1>
            {tags && (
              <div className={`${styles.contentRow} ${styles.tagRow}`}>
                {tags.map((tag, i) => {
                  return (
                    <LocalizedTag
                      className={`${styles.tag} ${theme ? styles.black : ""}`}
                      tagId={tag}
                      key={i}
                    />
                  );
                })}
              </div>
            )}
            <div className={`${styles.contentRow}`}>
              <div
                className={styles.contentCol}
                style={{
                  marginRight: "10%",
                }}
              >
                <span className={styles.contentLabel}>NAME</span>
                <span className={`${styles.contentText} ${kelson}`}>
                  <b>
                    <PrismicText prismicText={localizedDoc.conversation_name} />
                  </b>
                </span>
              </div>
              <div className={styles.contentCol}>
                <span className={styles.contentLabel}>SPOKE WITH</span>
                <span className={`${styles.contentText} ${kelson}`}>
                  <PrismicText
                    prismicText={localizedDoc.conversation_relationship}
                  />{" "}
                  <PrismicText
                    prismicText={localizedDoc.conversation_partner}
                  />
                </span>
              </div>
            </div>
            <div>
              <span className={`${styles.contentText} ${kelson}`}>
                <PrismicText
                  prismicText={localizedDoc.conversation_description}
                />
              </span>
            </div>
          </div>
        ) : (
          <div className={styles.loading}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingConversation;
