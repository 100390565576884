import React, { useState, createContext, useEffect } from "react";

import firebase from "firebase/app";
import "firebase/auth";

export const UserContext = createContext({
  user: null,
});

export function UserProvider({ children }) {
  const [user, setUser] = useState(firebase.auth().currentUser);

  const provider = {
    user,
    userChange: (newUser) => {
      setUser(newUser);
    },
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      provider.userChange(user);
    });
  }, []);

  return (
    <UserContext.Provider value={provider}>{children}</UserContext.Provider>
  );
}

export function isCurrentUserAdmin(onConfirmUserIsAdmin, onError) {
  const currentUser = firebase.auth().currentUser;

  if (!currentUser) {
    onConfirmUserIsAdmin(false);
    return;
  }

  currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      // Confirm the user is an Admin.
      if (!!idTokenResult?.claims?.admin) {
        onConfirmUserIsAdmin(true);
      } else {
        onConfirmUserIsAdmin(false);
      }
    })
    .catch((error) => {
      console.log(error);
      onError();
    });
}
