import "./App.css";
import "./style.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "components/header/Header/Header";
import Footer from "components/Footer/Footer";
import Main from "pages/Main";
import Form from "pages/Form/Form";
import FormSuccess from "pages/Form/FormSuccess";
import WhyConversations from "pages/About/WhyConversations/WhyConversations";
import Team from "pages/About/Team/Team";
import Partners from "pages/About/Partners/Partners";
import ContactUs from "pages/About/ContactUs/ContactUs";
import FAQ from "pages/About/FAQ/FAQ";
import HowItWorks from "pages/HowItWorks/HowItWorks";
import ConversationStarters from "pages/ConversationStarters/ConversationStarters";
import Approval from "pages/Approval/Approval";
import ConversationVerification from "pages/ConversationVerification/ConversationVerification";
import UserAuthEmailOnly from "pages/Account/UserAuthEmailOnly/UserAuthEmailOnly";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import ConversationAdvice from "pages/ConversationAdvice/ConversationAdvice";
import ClimateKnowledge from "pages/ClimateKnowledge/ClimateKnowledge";
import HowTo from "pages/HowTo/HowTo";
import UserProfile from "pages/UserProfile/UserProfile";
import LanguageAdvice from "pages/LanguageAdvice/LanguageAdvice";
import FeatureFlagAdmin from "pages/FeatureFlagAdmin/FeatureFlagAdmin";
import { useEffect, useState } from "react";
import { LanguageProvider } from "contexts/language/language";
import { UserProvider } from "contexts/user";
import { mediaQueryListener } from "utils/media-query";
import { docType } from "utils/prismic";
import CookieConsent from "react-cookie-consent";
import { FeatureFlagProvider } from "contexts/featureFlags/featureFlags";
import PrismicText from "components/PrismicText/PrismicText";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const maxMobileWidth = 1100;

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < maxMobileWidth);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { cookie_banner } = usePrismicContent({
    prismicDocTypes: [docType.cookie_banner],
  });

  useEffect(() => {
    return mediaQueryListener(maxMobileWidth, (isUnderMaxWidth) => {
      setIsMobile(isUnderMaxWidth);
    });
  }, []);

  const appHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    const listener = window.addEventListener("resize", appHeight);

    return window.removeEventListener("resize", listener);
  }, []);

  appHeight();

  return (
    <FeatureFlagProvider>
      <LanguageProvider>
        <UserProvider>
          <div
            className={`container ${isMobileMenuOpen ? "noScroll" : ""}`}
            id="container"
          >
            <div className="bg_overlay"></div>

            <Router>
              <Switch>
                <Route path="/form/:campaign">
                  <Form />
                  <Footer />
                </Route>
                <Route path="/form">
                  <Form />
                  <Footer />
                </Route>
                <Route path="/formSuccess/:verificationId">
                  <FormSuccess />
                  <Footer />
                </Route>
                <Route path="/formSuccess">
                  <FormSuccess />
                  <Footer />
                </Route>
                <Route path="/whyConversations">
                  <WhyConversations />
                  <Footer />
                </Route>
                <Route path="/team">
                  <Team />
                  <Footer />
                </Route>
                <Route path="/partners">
                  <Partners />
                  <Footer />
                </Route>
                <Route path="/contact">
                  <ContactUs />
                  <Footer />
                </Route>
                <Route path="/faq">
                  <FAQ />
                  <Footer />
                </Route>
                <Route path="/how">
                  <HowItWorks />
                  <Footer />
                </Route>
                <Route path="/advice">
                  <ConversationAdvice />
                  <Footer />
                </Route>
                <Route path="/starters">
                  <ConversationStarters />
                  <Footer />
                </Route>
                <Route path="/howTo">
                  <HowTo />
                  <Footer />
                </Route>
                <Route path="/knowledge">
                  <ClimateKnowledge />
                  <Footer />
                </Route>
                <Route path="/legalStuff">
                  <PrivacyPolicy />
                  <Footer />
                </Route>
                <Route path="/signup">
                  <UserAuthEmailOnly />
                  <Footer />
                </Route>
                <Route path="/language">
                  <LanguageAdvice />
                  <Footer />
                </Route>
                <Route path="/conversationVerification/:conversationId">
                  <ConversationVerification />
                  <Footer />
                </Route>
                <Route path="/map/campaign/:campaign">
                  <Main onLanding={false} isMobile={isMobile} />
                </Route>
                <Route path="/map/:preloadedConversationId">
                  <Main onLanding={false} isMobile={isMobile} />
                </Route>
                <Route path="/map">
                  <Main onLanding={false} isMobile={isMobile} />
                </Route>
                <Route path="/user/:userId">
                  <UserProfile />
                  <Footer />
                </Route>
                {/* Admin Page */}
                <Route path="/approval">
                  <Approval />
                  <Footer />
                </Route>
                {/* Feature Flag Admin Page */}
                <Route path="/featureflagadmin">
                  <FeatureFlagAdmin />
                  <Footer />
                </Route>
                <Route path="/:campaign">
                  <Main onLanding={true} isMobile={isMobile} />
                </Route>
                <Route path="/">
                  <Main onLanding={true} isMobile={isMobile} />
                </Route>
              </Switch>

              <Header
                isMobile={isMobile}
                onClickMobileMenu={(isOpen) => {
                  setIsMobileMenuOpen(isOpen);
                }}
              />

              {cookie_banner && (
                <CookieConsent
                  containerClasses="cookieConsent"
                  buttonClasses="cookieConsent-button"
                  buttonText={cookie_banner.accept_button_text}
                  expires={30}
                >
                  <PrismicText prismicText={cookie_banner.body} />
                </CookieConsent>
              )}
            </Router>
          </div>
        </UserProvider>
      </LanguageProvider>
    </FeatureFlagProvider>
  );
}

export default App;
