import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingPage from "components/LoadingPage/LoadingPage";
import useTopScroll from "hooks/useTopScroll";
import { functionDomain } from "config";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
// } from "components/SocialShareButtons/SocialShareButtons";

import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";

import styles from "./ConversationVerification.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const ConversationVerification = (props) => {
  useTopScroll();
  let { conversationId } = useParams();
  const [loading, setLoading] = useState(true);
  const [successful, setSuccessful] = useState(false);
  const { conversation_verification_page, share_message } = usePrismicContent({
    prismicDocTypes: [
      docType.conversation_verification_page,
      docType.share_message,
    ],
  });
  const textLoading = conversation_verification_page && share_message;

  useEffect(() => {
    const options = {
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    };
    axios
      .post(
        `${functionDomain}/pendingConversations/${conversationId.toString()}`,
        options
      )
      .then((response) => {
        setSuccessful(true);
        setLoading(false);
      })
      .catch((error) => {
        setSuccessful(false);
        setLoading(false);
        console.error(`Error verifying conversation: ${error}`);
      });
  }, [conversationId]);

  return (
    <div className={styles.container}>
      {!textLoading && <LoadingPage light />}
      {textLoading && (
        <div className={styles.content}>
          {loading && (
            <>
              <span className={`${textStyles.largeTitleLight}`}>
                {RichText.asText(
                  conversation_verification_page.verification_title__loading
                )}
              </span>
            </>
          )}
          {!loading && successful && (
            <>
              <span className={`${textStyles.largeTitleLight}`}>
                {RichText.asText(
                  conversation_verification_page.verification_title__success
                )}
              </span>
              <span className={`${textStyles.textContent}`}>
                <PrismicText
                  prismicText={conversation_verification_page.verification_body}
                />
              </span>
              {/* TODO: Put back social share buttons */}
              {/* <div className={styles.shareButtons}>
                <FacebookShareButton
                  shareMessage={RichText.asText(
                    share_message.facebook_share_message
                  )}
                />
                <TwitterShareButton
                  shareMessage={RichText.asText(
                    share_message.twitter_share_message
                  )}
                />
              </div> */}
            </>
          )}
          {!loading && !successful && (
            <>
              <span className={`${textStyles.largeTitleLight}`}>
                {RichText.asText(
                  conversation_verification_page.verification_title__failure
                )}
              </span>
              <span className={`${textStyles.textContent}`}>
                <PrismicText
                  prismicText={
                    conversation_verification_page.verification_body__failure
                  }
                />
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ConversationVerification;
