import React, { useContext } from "react";
import { RichText } from "prismic-reactjs";
import { linkResolver, htmlSerializer } from "utils/prismic";
import { LanguageContext } from "contexts/language/language";
import { defaultLanguage } from "contexts/language/languageConstants";
import get from "lodash/get";

/**
 * Given a document type and campaign, returns a PrismicText generator that is
 * linked to that document and only needs to be given a path to the exact text content.
 * @param {params} params
 * @param {string} prismicDocType document type id to retrieve from dictionary
 * @param {string} campaign
 */
export const usePrismicDocumentTextGenerator = ({
  prismicDocType,
  campaign,
}) => {
  const { dictionary, userLanguagePrismic } = useContext(LanguageContext);
  let document = get(dictionary, [userLanguagePrismic, prismicDocType], null);
  if (!document) {
    document = get(dictionary, [defaultLanguage, prismicDocType], null);
  }
  if (!document) {
    return () => null;
  }
  const docContent = campaign ? document[campaign] : document;

  return ({ path }) => {
    const text = get(docContent, path, null);

    if (!text) return null;

    return (
      <RichText
        render={text}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializer}
      />
    );
  };
};

/**
 * Grabs Prismic content for all given document types and an optional campaign
 */
export const usePrismicContent = ({ prismicDocTypes, campaign }) => {
  const { dictionary, userLanguagePrismic } = useContext(LanguageContext);

  const content = {};
  prismicDocTypes.forEach((prismicDocType) => {
    let document = get(dictionary, [userLanguagePrismic, prismicDocType], null);
    if (!document) {
      document = get(dictionary, [defaultLanguage, prismicDocType], null);
    }
    if (!document) {
      return;
    }
    const docContent = campaign ? document[campaign] : document;
    content[prismicDocType] = docContent;
  });

  return content;
};

const PrismicText = ({ prismicText }) => {
  return (
    <RichText
      render={prismicText}
      linkResolver={linkResolver}
      htmlSerializer={htmlSerializer}
    />
  );
};

export default PrismicText;
