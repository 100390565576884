import React, { useState } from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import useTopScroll from "hooks/useTopScroll";

import styles from "./ConversationStarters.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const ConversationStarters = (props) => {
  useTopScroll();
  const { conversation_starters_page } = usePrismicContent({
    prismicDocTypes: [docType.conversation_starters_page],
  });

  return (
    <div className={styles.container}>
      {!conversation_starters_page && <LoadingPage />}
      {conversation_starters_page && (
        <div className={styles.content}>
          <span className={textStyles.largeTitleDark}>
            {RichText.asText(conversation_starters_page.title)}
          </span>
          <PrismicText prismicText={conversation_starters_page.body} />
        </div>
      )}
    </div>
  );
};

export default ConversationStarters;
