import React from "react";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

import styles from "./PhotoThumb.module.css";

class PhotoThumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (file && !loading && !thumb) {
      this.setState({ loading: true }, () => {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result });
        };

        reader.readAsDataURL(file);
      });
    }

    if (!file) {
      return null;
    }

    if (loading) {
      return (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      );
    }

    return <img src={thumb} alt={file.name} className={styles.imgThumbnail} />;
  }
}

export default PhotoThumb;
