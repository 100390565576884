import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { mailchimpConfig } from "utils/mailchimp-config";
import parser from "html-react-parser";

import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";

import styles from "./MailchimpSubscribeForm.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const MailchimpSubscribeForm = (props) => {
  const { mailchimp_subscribe } = usePrismicContent({
    prismicDocTypes: [docType.mailchimp_subscribe],
  });

  let email;
  const submit = (onValidated) =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated &&
    onValidated({
      EMAIL: email.value,
    });

  const messageStyleClassConfig = {
    sending: styles.sending,
    error: styles.error,
    success: styles.success,
  };

  return (
    <div className={styles.container}>
      {mailchimp_subscribe && (
        <MailchimpSubscribe
          url={mailchimpConfig.subscribeEndpoint}
          render={({ subscribe, status, message }) => (
            <div className={styles.formContainer}>
              <div className={styles.label}>
                <PrismicText prismicText={mailchimp_subscribe.label} />
              </div>
              <div className={styles.form}>
                <input
                  ref={(node) => (email = node)}
                  type="email"
                  placeholder={mailchimp_subscribe.placeholder}
                />
                <button onClick={() => submit(subscribe)}>
                  {mailchimp_subscribe.submit}
                </button>
              </div>
              {message && (
                <div
                  className={`${styles.formMessage} ${messageStyleClassConfig[status]}`}
                >
                  {parser(message)}
                </div>
              )}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default MailchimpSubscribeForm;
