import React, { useEffect, useRef, useState } from "react";
import PhotoThumb from "components/form/PhotoThumb/PhotoThumb";
import { MBs } from "utils/file-utils";

import styles from "./FileUpload.module.css";
import { RichText } from "prismic-reactjs";
import PrismicText from "components/PrismicText/PrismicText";
import Compress from "compress.js";

const FileUpload = ({
  onSetFile,
  onFindError,
  fileUploadLabels,
  disabled = false,
}) => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef();

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFile(files[0]);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFile(fileInputRef.current.files[0]);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const handleFile = (file) => {
    const error = onFindError(file);
    onSetFile(file);
    compressFile(file);
    if (!error) {
      if (file.size > MBs(0.5)) {
        compressFile(file, setFile);
      } else {
        setFile(file);
      }
      return;
    }
    setFile(null);
  };

  const compressFile = (file, onSuccess) => {
    const compress = new Compress();
    compress
      .compress([file], {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      })
      .then((data) => {
        const newFile = Compress.convertBase64ToFile(data[0].data, data[0].ext);
        newFile.name = file.name;
        if (onSuccess) onSuccess(newFile);
      });
  };

  // If upload becomes disabled, remove any selected files
  useEffect(() => {
    if (disabled) {
      setFile(null);
      onSetFile(null);
    }
  }, [disabled]);

  return (
    <>
      <div className={styles.uploadContainer}>
        <div
          className={`${styles.dropContainer} ${
            disabled ? styles.disabled : ""
          }`}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={fileInputClicked}
        >
          {file && (
            <>
              <PhotoThumb file={file} />
              <div
                className={`${styles.button} ${styles.removePhoto}`}
                onClick={() => {
                  setFile(null);
                  onSetFile(null);
                }}
              >
                {RichText.asText(fileUploadLabels.input_remove_button)}
              </div>
            </>
          )}
          {!file && <PrismicText prismicText={fileUploadLabels.input_text} />}

          <div className={styles.button}>
            {RichText.asText(fileUploadLabels.input_select_button)}
          </div>
          <input
            ref={fileInputRef}
            className={styles.fileInput}
            type="file"
            onChange={filesSelected}
          />
        </div>
      </div>
    </>
  );
};

export default FileUpload;
