import React from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import ClimateKnowledgeBlock from "components/ClimateKnowledgeBlock/ClimateKnowledgeBlock";
import useTopScroll from "hooks/useTopScroll";

import styles from "./ClimateKnowledge.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const ClimateKnowledge = (props) => {
  useTopScroll();
  const { climate_knowledge_page } = usePrismicContent({
    prismicDocTypes: [docType.climate_knowledge_page],
  });

  return (
    <div className={styles.container}>
      {!climate_knowledge_page && <LoadingPage />}
      {climate_knowledge_page && (
        <div className={styles.content}>
          <span className={textStyles.largeTitleDark}>
            {RichText.asText(climate_knowledge_page.title)}
          </span>
          <PrismicText prismicText={climate_knowledge_page.intro} />
          {climate_knowledge_page.sections.map((section) => {
            return <ClimateKnowledgeBlock sectionDoc={section} />;
          })}
        </div>
      )}
    </div>
  );
};

export default ClimateKnowledge;
