import React from "react";
import { Link } from "react-router-dom";
import {
  TwitterLinkButton,
  InstagramLinkButton,
} from "components/SocialLinkButtons/SocialLinkButtons";

import { docType } from "utils/prismic";

import styles from "./Footer.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const Footer = () => {
  const { site_title, navigation } = usePrismicContent({
    prismicDocTypes: [docType.site_title, docType.navigation],
  });

  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.title}>{site_title?.site_title_text}</div>
        <div className={styles.share}>
          <TwitterLinkButton /> <InstagramLinkButton />
        </div>
        <div className={styles.info}>
          &#169; {new Date().getFullYear()} Talk Climate Change
          <br />
          <br />
          <Link to="/legalStuff">{navigation?.privacy_policy}</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
