import React, { useState, createRef } from "react";
import ReactTags from "react-tag-autocomplete";

import "./FormTagger.css";

const MAX_TAGS = 5;

function SuggestionComponent({ item, query }) {
  return item.hidden ? (
    ""
  ) : (
    <span id={item.id} className={item.name === query ? "match" : "no-match"}>
      {item.name}
    </span>
  );
}

const setupSuggestions = (suggestions) => {
  return Object.keys(suggestions).map((suggestionId) => {
    return { id: suggestionId, name: suggestions[suggestionId], hidden: false };
  });
};

// TODO: text translate
const FormTagger = ({ onChangeTags, onTouched, localizedTags }) => {
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState(
    setupSuggestions(localizedTags)
  );
  const reactTags = createRef();

  const setTagsAndSendChanges = (newTags) => {
    setTags(newTags);
    onChangeTags(getFormattedTagArray(newTags));
  };

  const getFormattedTagArray = (unformattedTags) => {
    const tagArray = unformattedTags.map((tag) => {
      return {
        id: tag.id,
        name: tag.name,
      };
    });
    return tagArray;
  };

  const setSuggestionHidden = (tag, hidden) => {
    for (let index = 0; index < suggestions.length; index++) {
      if (suggestions[index].id === tag.id) {
        suggestions[index].hidden = hidden;
        break;
      }
    }
  };

  const onDelete = (i) => {
    setSuggestionHidden(tags[i], false);

    const newTags = tags.slice(0);
    newTags.splice(i, 1);
    setTagsAndSendChanges(newTags);
  };

  const onAddition = (tag) => {
    if (tags.length >= MAX_TAGS) {
      return;
    }

    setSuggestionHidden(tag, true);

    const newTags = [...tags, tag];
    setTagsAndSendChanges(newTags);
  };

  return (
    <ReactTags
      ref={reactTags}
      tags={tags}
      suggestionComponent={SuggestionComponent}
      suggestions={suggestions}
      minQueryLength={0}
      maxSuggestionsLength={100}
      onDelete={onDelete}
      onAddition={onAddition}
      onFocus={() => {
        if (onTouched) {
          onTouched(true);
        }
      }}
    />
  );
};

export default FormTagger;
