import React from "react";
import Button from "components/Button/Button";

import styles from "./FormSubmit.module.css";

const FormSubmit = (props) => {
  const { dirty, isValid, label } = props;

  return (
    <button
      type="submit"
      className={`${styles.button} ${
        !(dirty && isValid) ? styles.disabled : ""
      }`}
      disabled={!(dirty && isValid)}
    >
      <Button highlight>{label}</Button>
    </button>
  );
};

export default FormSubmit;
