import React, { useContext } from "react";
import { FeatureFlagContext } from "contexts/featureFlags/featureFlags";

import styles from "./FeatureFlagMenu.module.css";

const FeatureFlagMenu = () => {
  const { featureFlags, toggleFeatureFlag } = useContext(FeatureFlagContext);

  if (Object.keys(featureFlags).length === 0) return null;

  return (
    <>
      {Object.keys(featureFlags).map((ff) => {
        const ffIsOn = featureFlags[ff];
        return (
          <div key={ff}>
            <span>
              {ff} ({ffIsOn ? "Enabled" : "Disabled"})
            </span>{" "}
            <button
              className={styles.button}
              onClick={() => toggleFeatureFlag(ff)}
            >
              Turn {ffIsOn ? "Off" : "On"}
            </button>
          </div>
        );
      })}
    </>
  );
};

export default FeatureFlagMenu;
