import React from "react";
import { RichText } from "prismic-reactjs";
import PrismicText from "components/PrismicText/PrismicText";
import { LocalizedTag } from "contexts/language/language";

import styles from "./ConversationPopup.module.css";

const ConversationPopupBody = ({
  conversationData,
  tags,
  windowHeight,
  isSingleColumn,
  localizedDoc,
}) => {
  let uniqueTags = Array.from(new Set(tags));

  let partnerRelationship = "";
  if (
    conversationData.partnerRelationship &&
    conversationData.partnerRelationship.toLowerCase() !== "other"
  ) {
    const relationshipKey = localizedDoc.relationships.relationship_key;
    partnerRelationship = relationshipKey.find(
      (r) => r.relationship_id === conversationData.partnerRelationship
    ).relationship_text;
  }

  const date = new Date(conversationData.createdAt);
  const month = date.toLocaleString("default", { month: "long" });

  return (
    <>
      <div
        className={styles.content}
        style={{ height: windowHeight * (isSingleColumn ? 0.7 : 0.65) }}
      >
        <div
          className={`${styles.contentRow} ${styles.noMargin}`}
          style={{ justifyContent: "space-between" }}
        >
          <div className={styles.contentHeader}>
            <div>
              <span className={styles.contentLocation}>
                {conversationData.locationString}
              </span>
              <div className={styles.metaRow}>
                <span className={`${styles.meta}`}>
                  {month} {date.getDate()}, {date.getFullYear()}
                </span>
              </div>
            </div>

            {conversationData.photo && isSingleColumn ? (
              <div
                className={styles.image}
                style={{
                  backgroundImage: `url(${conversationData.photo})`,
                  backgroundSize: "cover",
                }}
              ></div>
            ) : null}

            {uniqueTags && uniqueTags.length > 0 && (
              <div className={`${styles.contentRow} ${styles.tagRow}`}>
                {uniqueTags.map((tag, i) => {
                  return <LocalizedTag className={styles.tag} tagId={tag} />;
                })}
              </div>
            )}

            <div className={`${styles.contentRow}`}>
              <div
                className={styles.contentCol}
                style={{
                  marginRight: "10%",
                }}
              >
                <span className={styles.contentLabel}>
                  <PrismicText prismicText={localizedDoc.name} />
                </span>
                <span className={styles.contentText}>
                  <b>{conversationData.name}</b>
                </span>
              </div>
              <div className={styles.contentCol}>
                <span className={styles.contentLabel}>
                  {RichText.asText(localizedDoc.partner)}
                </span>
                <span className={styles.contentText}>
                  <b>{`${partnerRelationship} ${conversationData.partner}`}</b>
                </span>
              </div>
            </div>
          </div>
          {conversationData.photo && !isSingleColumn ? (
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${conversationData.photo})`,
                backgroundSize: "cover",
              }}
            ></div>
          ) : null}
        </div>

        <div className={styles.contentBottom}>
          <div className={styles.contentScroll}>
            <div className={styles.contentRow}>
              <div className={styles.contentCol}>
                <span className={styles.contentLabel}>
                  <PrismicText prismicText={localizedDoc.description} />
                </span>
                <span className={styles.contentText}>
                  {conversationData.description}
                </span>
              </div>
            </div>

            {conversationData.message && (
              <div className={styles.contentRow}>
                <div className={styles.contentCol}>
                  <span className={styles.contentLabel}>
                    <PrismicText prismicText={localizedDoc.message} />
                  </span>
                  <span className={styles.contentText}>
                    {conversationData.message}
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* {!isSingleColumn && <div className={styles.contentBottomFade} />} */}
        </div>
      </div>
      {/* {isSingleColumn && (
        <div
          className={styles.contentBottomFade}
          style={{ height: "20px", marginBottom: "16px" }}
        />
      )} */}
    </>
  );
};

export default ConversationPopupBody;
