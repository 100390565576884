import { firebaseAuth } from "firebase/config";

export const getDataForDatabase = (data) => {
  var formData = new FormData();
  formData.append("campaign", data.campaign);
  formData.append("name", data.first_name);
  formData.append("partnerRelationship", data.partner_relationship);
  formData.append("partner", data.partner);
  formData.append("location", JSON.stringify(data.location));
  formData.append("desc", data.description);
  formData.append("message", data.message);
  formData.append("tags", JSON.stringify(data.tags));
  formData.append(
    "uid",
    getDatabaseUID(data.location.latitude, data.location.longitude)
  );
  formData.append("isOrgConversation", data.is_org_conversation);
  formData.append("orgName", data.org_name);
  formData.append("socialMediaPermission", data.social_media_permission);
  formData.append("socialMediaHandles", data.social_media_handles);
  formData.append("email", data.email);
  formData.append("age_agreement", data.age_agreement);
  formData.append("emailUpdatesPermission", data.email_updates_permission);
  formData.append("recaptcha", data.recaptcha);

  if (data.age_agreement !== "minor") {
    formData.append("file", data.photo);
  }

  // TODO: use context?
  const user = firebaseAuth.currentUser;
  if (user) {
    formData.append("userId", user.uid);

    if (user.emailVerified) {
      formData.append("email", user.email);
    }
  }

  return formData;
};

// TODO: add user info for sign-in check

export const initialValues = ({ initialCampaign }) => {
  return {
    campaign: initialCampaign,
    first_name: "",
    location: {},
    partner: "",
    partner_relationship: "",
    description: "",
    message: "",
    email: "",
    photo: null,
    tags: [],
    is_org_conversation: false,
    org_name: "",
    social_media_permission: false,
    social_media_handles: "",
    terms_agreement: false,
    age_agreement: "",
    email_updates_permission: false,
    recaptcha: "",
  };
};

const isLengthZero = (v) => v.length === 0;

export const fieldValueEmptyCheckers = {
  campaign: (v) => true,
  first_name: isLengthZero,
  location: (v) => typeof v === "object" && Object.keys(v).length === 0,
  partner: isLengthZero,
  partner_relationship: isLengthZero,
  description: isLengthZero,
  message: isLengthZero,
  email: isLengthZero,
  photo: (v) => v !== null,
  tags: isLengthZero,
  is_org_conversation: (v) => true,
  org_name: isLengthZero,
  social_media_permission: (v) => true,
  social_media_handles: isLengthZero,
  terms_agreement: (v) => !v,
  age_agreement: isLengthZero,
  email_updates_permission: (v) => true,
  recaptcha: isLengthZero,
  campaign: (v) => true,
};

export const getDatabaseUID = (lat, lng) => {
  const time = new Date().getTime().toString();
  const s = lat.toString() + lng.toString() + time.toString();
  var hash = 0;

  if (s.length === 0) return hash;

  for (let i = 0; i < s.length; i++) {
    let char = s.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash.toString();
};

export const formatFileSize = (bytes) => {
  const sizes = [" Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 1) return "1 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
};

export const parseTags = (tagJsonString) => {
  if (!tagJsonString) {
    return [];
  }

  return JSON.parse(tagJsonString).map((item) => {
    return item.name;
  });
};
