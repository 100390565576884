import React from "react";

import { RichText } from "prismic-reactjs";
import PrismicText from "components/PrismicText/PrismicText";

import styles from "./HowItWorks.module.css";
import textStyles from "style/TextStyle.module.css";

const HowItWorksBlock = (props) => {
  const { highlightDoc, titleDoc, paragraphDocs, children } = props;

  return (
    <div className={styles.howBlock}>
      <span className={styles.highlight}>{RichText.asText(highlightDoc)}</span>
      <div className={textStyles.textContent}>
        <div className={styles.howTitle}>
          <span className={textStyles.midTitleAccent}>
            {RichText.asText(titleDoc)}
          </span>
        </div>

        <PrismicText prismicText={paragraphDocs} />
        {children}
      </div>
    </div>
  );
};

export default HowItWorksBlock;
