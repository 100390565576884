import React, { useState, useContext } from "react";
import { log } from "firebase/config";
import { UserContext } from "contexts/user";
import { useHistory, useParams } from "react-router-dom";
import LoadingPage from "components/LoadingPage/LoadingPage";
import ConversationForm from "components/form/ConversationForm/ConversationForm";
import { getDataForDatabase } from "utils/data-utils";
import axios from "axios";
import useTopScroll from "hooks/useTopScroll";
import { functionDomain } from "config";

import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";

import styles from "./Form.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";
import LanguageSelector from "components/LanguageSelector/LanguageSelector";
import { FeatureFlagContext } from "contexts/featureFlags/featureFlags";
import { FeatureFlag } from "contexts/featureFlags/featureFlags";

const Form = (props) => {
  useTopScroll();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const userContext = useContext(UserContext);
  const { featureFlags } = useContext(FeatureFlagContext);

  // Preload campaign-specific form, if available
  let { campaign } = useParams();

  // Get  copy
  const {
    form_page,
    form_questions,
    relationships,
    conversation_tags,
    campaign_list,
  } = usePrismicContent({
    prismicDocTypes: [
      docType.form_page,
      docType.form_questions,
      docType.relationships,
      docType.conversation_tags,
      docType.campaign_list,
    ],
  });
  const campaignList = campaign_list
    ? campaign_list.campaign
        .map((campaign) => {
          return {
            campaign_id: campaign.campaign_id,
            name: campaign.name[0].text,
            include: campaign.include_in_form_dropdown,
          };
        })
        .filter((campaign) => campaign.include)
    : [];
  const formData = form_page
    ? {
        ...form_page,
        form_questions,
        relationships: relationships?.relationship_key,
        conversation_tags,
        campaign_list: campaignList,
      }
    : null;

  const SubmitForm = (values) => {
    setSubmitting(true);
    uploadData(values);
    log("FORM_SUBMIT");
  };

  const uploadWithAuthorizationHeader = (options, uploadCallback) => {
    if (!userContext.user) {
      uploadCallback(options);
      return;
    }

    userContext.user
      .getIdToken()
      .then((idToken) => {
        options.headers["Authorization"] = `Bearer ${idToken}`;
        uploadCallback(options);
      })
      .catch((error) => {
        console.error(`Error retrieving id token of user:  ${error}`);
      });
  };

  const uploadData = (values) => {
    const postData = (options) => {
      axios
        .post(
          `${functionDomain}/conversation`,
          getDataForDatabase(values),
          options
        )
        .then((response) => {
          const verificationId = response.data.verificationId;
          if (verificationId) history.push(`/formSuccess/${verificationId}`);
          else history.push("/formSuccess");

          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          console.error("Error submitting form: " + error);
        });
    };

    uploadWithAuthorizationHeader(
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      postData
    );
  };

  return (
    <div className={styles.formPage}>
      {formData && (
        <img
          className={styles.banner}
          src={formData.form_image.url}
          alt={formData.form_image.alt}
        />
      )}
      {!formData && (
        <div className={styles.loadingContainer}>
          <LoadingPage light />
        </div>
      )}
      {formData && (
        <div className={styles.formContainer}>
          <div className={styles.formIntro}>
            <span
              className={`${textStyles.largeTitleDark} ${styles.formIntroTitle}`}
            >
              {RichText.asText(formData.form_title)}
            </span>
            <span
              className={`${textStyles.textContent} ${styles.formIntroText}`}
            >
              <PrismicText prismicText={formData.form_description} />
              <LanguageSelector />
            </span>
          </div>
          <ConversationForm
            onSubmitData={SubmitForm}
            isSubmitting={submitting}
            localizedDoc={formData}
            initialCampaign={
              // If there's a valid campaign that matches the campaign param, use it
              formData.campaign_list.filter((c) => c.campaign_id === campaign)
                .length > 0
                ? campaign
                : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

export default Form;
