import React, { useContext, useEffect, useState } from "react";
import { firebaseAuth, log } from "firebase/config";
import firebase from "firebase/app";
import { UserContext } from "contexts/user";
import { useHistory } from "react-router-dom";
import useTopScroll from "hooks/useTopScroll";

import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import { Formik } from "formik";
import FormBlock from "components/form/FormBlock/FormBlock";
import GoogleButton from "react-google-button";

import LoadingPage from "components/LoadingPage/LoadingPage";

import styles from "./UserAuth.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const valueExists = (value) => {
  return value !== null && value !== undefined && value !== "";
};

const UserAuthEmailOnly = () => {
  useTopScroll();
  const [inEmailSignInState, setInEmailSignInState] = useState(false);
  const [firebaseError, setFirebaseError] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [message, setMessage] = useState(null);

  const userContext = useContext(UserContext);
  const history = useHistory();

  const { sign_in_page } = usePrismicContent({
    prismicDocTypes: [docType.sign_in_page],
  });

  const [inLinkReturnState, setInLinkReturnState] = useState(false);

  var actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: window.location.href,
    // This must be true.
    handleCodeInApp: true,
  };

  const handleSignInWithEmail = (email) => {
    firebase.auth().signOut();
    firebaseAuth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setMessage("Please check your email inbox for a sign-in link.");
      })
      .catch((error) => {
        var errorMessage = error.message;
        setMessage(errorMessage);
      });
  };

  const signInWithGoogle = () => {
    firebase.auth().signOut();
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        log("LOGIN_GOOGLE_SUCCESS");
      })
      .catch((e) => {
        console.error(e);
        setFirebaseError(e);
      });
  };

  const validate = (values) => {
    let errors = {};

    if (!values.email) {
      errors.required = sign_in_page.required_label;
    }

    return errors;
  };

  const signInWithLinkFromEmail = (email) => {
    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(function (result) {
        log("LOGIN_EMAIL_SUCCESS");
        setInEmailSignInState(false);
        setInLinkReturnState(false);
        // Clear the URL to remove the sign-in link parameters.
        if (history && history.replaceState) {
          window.history.replaceState(
            {},
            document.title,
            window.location.href.split("?")[0]
          );
        }
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorMessage = error.message;
        console.error(error);
        setMessage(errorMessage);
      });
  };

  useEffect(() => {
    if (inLinkReturnState) {
      return;
    }

    if (firebaseAuth.isSignInWithEmailLink(window.location.href)) {
      // You can also get the other parameters passed in the query string such as state=STATE.
      // Get the email if available.
      const email = window.localStorage.getItem("emailForSignIn");

      if (email) {
        setUserEmail(email);
        signInWithLinkFromEmail(email);
      }

      setInLinkReturnState(true);
      return;
    }

    setInLinkReturnState(false);
  }, []);

  return (
    <div className={`section_container ${styles.authContainer}`}>
      <div className={styles.signIn}>
        {sign_in_page && (
          <>
            <span
              className={`${textStyles.largeTitleDark} ${styles.authTitle}`}
              onClick={() => {
                window.location.reload();
              }}
            >
              {RichText.asText(sign_in_page.sign_in_register_title)}
            </span>

            <span className={styles.authDescription}>
              <PrismicText
                prismicText={sign_in_page.sign_in_register_description}
              />
            </span>

            {message && <span className={styles.message}>{message}</span>}

            {!userContext.user && !inEmailSignInState && !inLinkReturnState && (
              <>
                <div className={styles.emailSignIn}>
                  <button
                    className={styles.submit}
                    onClick={() => setInEmailSignInState(true)}
                  >
                    Sign in or Sign Up with Email
                  </button>
                </div>

                <div className={styles.googleSignIn}>
                  <GoogleButton onClick={signInWithGoogle} />
                </div>
              </>
            )}

            {!userContext.user &&
              (inEmailSignInState || (inLinkReturnState && !userEmail)) && (
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validate={validate}
                  onSubmit={(values) => {
                    if (!values.email) {
                      return;
                    }

                    if (inLinkReturnState) {
                      signInWithLinkFromEmail(values.email);
                      return;
                    }

                    handleSignInWithEmail(values.email);
                  }}
                >
                  {(formik) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                    } = formik;

                    return (
                      <form
                        onSubmit={handleSubmit}
                        className={styles.signInForm}
                      >
                        <div className={styles.formGroup}>
                          <FormBlock
                            name="email"
                            label={
                              inLinkReturnState
                                ? "Please provide the email you'd like to sign-in with for confirmation"
                                : sign_in_page.email_label
                            }
                            sublabel={RichText.asText(
                              sign_in_page.email_sublabel
                            )}
                            requiredLabel={sign_in_page.required_label}
                            error={errors.email}
                            hasValue={valueExists(values.email)}
                            touched={touched.email}
                            required
                          >
                            <input
                              type="text"
                              name="email"
                              id="email"
                              placeholder=""
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={styles.input}
                            />
                          </FormBlock>
                        </div>
                        {!userContext.user && firebaseError && (
                          <span className={styles.firebaseError}>
                            {firebaseError}
                          </span>
                        )}

                        <button type="submit" className={styles.submit}>
                          {inLinkReturnState
                            ? "Sign in"
                            : sign_in_page.submit_label}
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              )}

            {userContext.user && sign_in_page && (
              <PrismicText prismicText={sign_in_page.signed_in_description} />
            )}
          </>
        )}
        {!sign_in_page && <LoadingPage />}
      </div>
    </div>
  );
};

export default UserAuthEmailOnly;
