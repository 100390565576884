/** By Tobias Sahlin https://github.com/tobiasahlin/SpinKit **/

import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ light }) => {
  return (
    // <div className="loadingSpinner">
    //   <div className={`${styles.skCircle1} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle2} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle3} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle4} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle5} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle6} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle7} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle8} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle9} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle10} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle11} ${styles.skCircle}`}></div>
    //   <div className={`${styles.skCircle12} ${styles.skCircle}`}></div>
    // </div>
    // <div className={`${styles.loadingSpinner}`}>
    //   <div className={`${styles.bounce1}`}></div>
    //   <div className={`${styles.bounce2}`}></div>
    //   <div className={`${styles.bounce3}`}></div>
    // </div>
    <div className={`lds-roller ${light ? "light" : ""}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingSpinner;
