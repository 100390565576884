import React, { useState } from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import useTopScroll from "hooks/useTopScroll";

import styles from "./ContactUs.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const ContactUs = () => {
  useTopScroll();

  const { contact_page } = usePrismicContent({
    prismicDocTypes: [docType.contact_page],
  });

  return (
    <div className={styles.container}>
      {!contact_page && <LoadingPage />}
      {contact_page && (
        <div className={styles.content}>
          <span className={textStyles.largeTitleDark}>
            {RichText.asText(contact_page.title)}
          </span>
          <PrismicText prismicText={contact_page.body} />
        </div>
      )}
    </div>
  );
};

export default ContactUs;
