// Use in an effect hook (make sure this is the RETURNED value so it gets cleaned up!)
// maxWidthNumber: integer
// onWindowWidthEvent: function(isUnderMaxWidth: boolean)
export const mediaQueryListener = (maxWidthNumber, onWindowWidthEvent) => {
  // Setup listener
  const mediaQuery = `(max-width: ${maxWidthNumber}px)`;
  const mediaQueryList = window.matchMedia(mediaQuery);

  const onMediaQueryEvent = (event) => {
    onWindowWidthEvent(event.matches);
  };

  mediaQueryList.addListener(onMediaQueryEvent);

  // Return cleanup listener
  return function cleanupListener() {
    mediaQueryList.removeListener(onMediaQueryEvent);
  };
};
