import React, { useState } from "react";
import { LocalizedTag } from "contexts/language/language";

import styles from "./ApprovalBlock.module.css";

const ApprovalBlock = ({ conversationData, onClickApprove, onClickReject }) => {
  const [conversationID, data] = conversationData;
  const [showConfirmReject, setShowConfirmReject] = useState(false);

  const [loading, setLoading] = useState(false);

  const onClickApproveButton = () => {
    setLoading(true);
    onClickApprove(conversationID, () => setLoading(false));
  };

  const onClickConfirmRejectButton = () => {
    setLoading(true);
    onClickReject(conversationID, data.photo, data.userId, data.email, () =>
      setLoading(false)
    );
    setShowConfirmReject(false);
  };

  return (
    <div className={`${styles.approvalBlock} ${loading ? styles.loading : ""}`}>
      <div className={styles.id}>{conversationID}</div>
      {data.photo && (
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${data.photo})`,
            backgroundSize: "cover",
          }}
        ></div>
      )}
      <div className={styles.contentRow}>
        <div className={styles.contentBlock}>
          <span className={styles.contentLabel}>CAMPAIGN</span>
          <span className={styles.contentText}>{data.campaign}</span>
        </div>
      </div>
      <div className={styles.contentRow}>
        <div className={styles.contentBlock}>
          <span className={styles.contentLabel}>NAME</span>
          <span className={styles.contentText}>{data.name}</span>
        </div>
        <div className={styles.contentBlock}>
          <span className={styles.contentLabel}>EMAIL</span>
          <span className={styles.contentText}>{data.email}</span>
        </div>
      </div>
      <div className={styles.contentRow}>
        <div className={styles.contentBlock}>
          <span className={styles.contentLabel}>PARTNER</span>
          <span className={styles.contentText}>{data.partner}</span>
        </div>
        <div className={styles.contentBlock}>
          <span className={styles.contentLabel}>PARTNER RELATIONSHIP</span>
          <span className={styles.contentText}>{data.partnerRelationship}</span>
        </div>
      </div>
      <div className={styles.contentRow}>
        <div className={styles.contentBlock}>
          <span className={styles.contentLabel}>LOCATION</span>
          <span className={styles.contentText}>{data.locationString}</span>
        </div>
      </div>
      <div className={styles.contentRow}>
        <div className={styles.contentBlock}>
          <span className={styles.contentLabel}>SUBMITTED AT</span>
          <span className={styles.contentText}>
            {new Date(data.createdAt).toString()}
          </span>
        </div>
      </div>
      <div className={styles.contentRow}>
        <div className={`${styles.contentBlock} ${styles.col}`}>
          <span className={styles.contentLabel}>TAGS</span>
          <div className={styles.contentRowTags} style={{ marginBottom: "0" }}>
            {data.tags &&
              Object.values(data.tags).map((tag, i) => {
                return (
                  <LocalizedTag
                    tagId={tag}
                    className={styles.tag}
                    key={`${conversationID}-${tag}`}
                  />
                );
              })}
          </div>
          {!data.tags && "No tags"}
        </div>
      </div>
      <div className={styles.contentRow}>
        <div className={`${styles.contentBlock} ${styles.col}`}>
          <span className={styles.contentLabel}>SPOKE ABOUT</span>
          <span className={styles.contentText}>{data.description}</span>
        </div>
      </div>
      <div className={styles.contentRow}>
        <div className={`${styles.contentBlock} ${styles.col}`}>
          <span className={styles.contentLabel}>MESSAGE</span>
          <span className={styles.contentText}>{data.message}</span>
        </div>
      </div>
      <div className={styles.contentRow}>
        <div className={styles.contentBlock}>
          <span className={styles.contentLabel}>SOCIAL MEDIA PERMISSION</span>
          <span className={styles.contentText}>
            {data.socialMediaPermission}
          </span>
        </div>
        <div className={styles.contentBlock}>
          <span className={styles.contentLabel}>SOCIAL MEDIA HANDLES</span>
          <span className={styles.contentText}>
            {data.socialMediaHandles ? data.socialMediaHandles : "None"}
          </span>
        </div>
      </div>
      <div className={`${styles.contentRow} ${styles.buttonRow}`}>
        <div
          onClick={onClickApproveButton}
          className={`${styles.button} ${styles.highlight}`}
        >
          Accept
        </div>
        <div className={styles.rejectButtonContainer}>
          <div
            onClick={() => setShowConfirmReject(true)}
            className={`${styles.button} ${styles.remove}`}
          >
            Remove
          </div>

          {showConfirmReject && (
            <div className={styles.confirmRejectRow}>
              Are you sure you want to remove this conversation? (It will be
              permanently deleted.)
              <div
                onClick={onClickConfirmRejectButton}
                className={`${styles.button} ${styles.confirmReject}`}
              >
                Yes, remove this conversation
              </div>
              <div
                onClick={() => {
                  setShowConfirmReject(false);
                }}
                className={styles.nevermind}
              >
                Nevermind
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalBlock;
