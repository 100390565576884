import React from "react";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

import styles from "./LoadingPage.module.css";

const LoadingPage = ({ light }) => {
  return (
    <div className={styles.container}>
      <LoadingSpinner light={light} />
    </div>
  );
};

export default LoadingPage;
