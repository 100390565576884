import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "contexts/user";
import useTopScroll from "hooks/useTopScroll";

import styles from "./FeatureFlagAdmin.module.css";
import textStyles from "style/TextStyle.module.css";
import LoadingPage from "components/LoadingPage/LoadingPage";
import { isCurrentUserAdmin } from "contexts/user";
import FeatureFlagMenu from "components/FeatureFlagMenu/FeatureFlagMenu";

const FeatureFlagAdmin = (props) => {
  useTopScroll();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  const userContext = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    if (!userContext.user) {
      setAuthorized(false);
      return;
    }

    isCurrentUserAdmin((isAdmin) => {
      setAuthorized(isAdmin);
      setLoading(false);
    });
  }, [userContext]);

  return (
    <div className={styles.container}>
      {loading && <LoadingPage />}
      {!loading && authorized && (
        <>
          <span className={textStyles.largeTitleDark}>Feature Flag Admin</span>
          <FeatureFlagMenu />
        </>
      )}
    </div>
  );
};

export default FeatureFlagAdmin;
