import React from "react";
import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import PrismicText from "components/PrismicText/PrismicText";
import { docType } from "utils/prismic";
import useTopScroll from "hooks/useTopScroll";

import styles from "./LanguageAdvice.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const LanguageAdvice = (props) => {
  useTopScroll();
  const { language_advice_page } = usePrismicContent({
    prismicDocTypes: [docType.language_advice_page],
  });

  return (
    <div className={styles.container}>
      {!language_advice_page && <LoadingPage />}
      {language_advice_page && (
        <div className={styles.content}>
          <span className={textStyles.largeTitleDark}>
            {RichText.asText(language_advice_page.title)}
          </span>
          <PrismicText prismicText={language_advice_page.body} />
        </div>
      )}
    </div>
  );
};

export default LanguageAdvice;
