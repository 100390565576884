import React from "react";
import Button from "components/Button/Button";

import { docType } from "utils/prismic";

import styles from "./CTA.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const CTA = ({
  submitConversation,
  submitAnotherConversation,
  seeTheMap,
  campaign,
  theme,
}) => {
  const { cta_buttons } = usePrismicContent({
    prismicDocTypes: [docType.cta_buttons],
  });
  const buttons = {};
  if (cta_buttons) {
    for (let button of cta_buttons?.buttons) {
      buttons[button.button_id] = button.button_label;
    }
  }

  return (
    <div className={styles.cta}>
      {cta_buttons && (
        <>
          {submitConversation && (
            <Button
              linkTo={`/form${campaign ? `/${campaign}` : ""}`}
              highlight
              theme={theme}
            >
              {buttons.submit_conversation}
            </Button>
          )}
          {submitAnotherConversation && (
            <Button
              linkTo={`/form${campaign ? `/${campaign}` : ""}`}
              highlight
              theme={theme}
            >
              {buttons.additional_conversation}
            </Button>
          )}
          {seeTheMap && (
            <Button
              linkTo={`/map${campaign ? `/campaign/${campaign}` : ""}`}
              theme={theme}
            >
              {buttons.see_map}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default CTA;
