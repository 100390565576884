import React, { useState, createContext } from "react";

export const FeatureFlag = {
  FORM_LANGUAGE_PICKER: "Form Language Picker",
};

const ffValues = {};

export const FeatureFlagContext = createContext(ffValues);

export function FeatureFlagProvider({ children }) {
  const [featureFlags, setFeatureFlags] = useState(ffValues);

  const provider = {
    featureFlags,
    toggleFeatureFlag: (featureFlag) => {
      setFeatureFlags({
        ...featureFlags,
        [featureFlag]: !featureFlags[featureFlag],
      });
    },
  };

  return (
    <FeatureFlagContext.Provider value={provider}>
      {children}
    </FeatureFlagContext.Provider>
  );
}
