import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Landing from "components/landing/Landing/Landing";
import Map from "components/map/Map";
import { docType } from "utils/prismic";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const Main = ({ onLanding, isMobile }) => {
  const [isLandingOpen, setIsLandingOpen] = useState(onLanding);
  const { campaign } = useParams();

  useEffect(() => {
    setIsLandingOpen(onLanding);
  }, [onLanding]);

  const { site_title } = usePrismicContent({
    prismicDocTypes: [docType.site_title],
  });

  return (
    <>
      {!isLandingOpen && !isMobile && (
        <Link to="/">
          <div className="mini_title">{site_title?.site_title_text}</div>
        </Link>
      )}
      <Map isActive={!isLandingOpen} />
      {isLandingOpen && <Landing campaign={campaign} />}
    </>
  );
};

export default Main;
