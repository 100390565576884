import React from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import useTopScroll from "hooks/useTopScroll";

import styles from "./ConversationAdvice.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const ConversationAdvice = (props) => {
  useTopScroll();
  const { conversation_advice_page } = usePrismicContent({
    prismicDocTypes: [docType.conversation_advice_page],
  });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {!conversation_advice_page && <LoadingPage />}
        {conversation_advice_page && (
          <>
            <span className={styles.aboutTitle}>
              <span className={textStyles.largeTitleDark}>
                {RichText.asText(conversation_advice_page.title)}
              </span>
            </span>
            <div className={styles.description}>
              <PrismicText prismicText={conversation_advice_page.description} />
            </div>
            {conversation_advice_page.advice_blocks.map((advice, index) => {
              return (
                <div className={styles.adviceBlock} key={index}>
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url('${advice.image.url})`,
                    }}
                    alt={advice.image.alt}
                  />
                  <div className={styles.adviceBlockText}>
                    <span className={styles.quote}>
                      <PrismicText prismicText={advice.quote} />
                    </span>
                    <span className={styles.name}>
                      <PrismicText prismicText={advice.name} />
                    </span>
                    <span className={styles.contributorTitle}>
                      <PrismicText prismicText={advice.contributor_title} />
                    </span>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default ConversationAdvice;
