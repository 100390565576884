import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/analytics";
import * as firebaseui from "firebaseui";

const firebaseConfig = {
  apiKey: "AIzaSyBmQEZlsPvx4fOUyVZ5EePevUzebqCYf8E",
  authDomain: "climateconvos-6348b.firebaseapp.com",
  projectId: "climateconvos-6348b",
  storageBucket: "climateconvos-6348b.appspot.com",
  messagingSenderId: "358890349927",
  appId: "1:358890349927:web:5af19ef70ee31f2de97bc2",
  measurementId: "G-W1FWDGLEYN",
};

const firebaseAuthOptions = {
  signInOptions: [
    {
      tosUrl: "/legalStuff",
      privacyPolicyUrl: function () {
        window.location.assign("/legalStuff");
      },
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          return false;
        },
        signInFailure: function (error) {
          console.error(`Error siging in user: ${error}`);
        },
      },
    },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firebaseAnalytics = firebase.analytics();
const logEvents = {
  FORM_SUBMIT: "form_submit",
  LOGIN_EMAIL_SUCCESS: "login_email_success",
  LOGIN_GOOGLE_SUCCESS: "login_google_success",
  VISIT_LANGUAGE_ADVICE: "visit_language_advice",
};
const log = (event) => {
  if (!logEvents[event]) {
    console.error(`Tried to log an event that does not exist: ${event}`);
    return;
  }
  firebaseAnalytics.logEvent(logEvents[event]);
};

const projectDatabase = firebase.database();
const firebaseAuth = firebase.auth();
const firebaseUI = new firebaseui.auth.AuthUI(firebaseAuth);

const pendingConversationsDir = "pendingConversations";
const acceptedConversationsDir = "acceptedConversations__Public";
const userAcceptedConversationsDir = "acceptedConversations";
const usersPublicDir = "users__Public";
const usersPrivateDir = "users__Private";
const metadataDir = "metadata";

export {
  pendingConversationsDir,
  acceptedConversationsDir,
  userAcceptedConversationsDir,
  usersPublicDir,
  usersPrivateDir,
  metadataDir,
  projectDatabase,
  firebaseAuth,
  firebaseUI,
  firebaseAuthOptions,
  log,
};
