import React, { useEffect, useState } from "react";
import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import useTopScroll from "hooks/useTopScroll";

import styles from "./FAQ.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const faqId = "faq";

const FAQ = () => {
  useTopScroll();
  const [scrollToComponent, setScrollToComponent] = useState(null);
  const { faq_page } = usePrismicContent({
    prismicDocTypes: [docType.faq_page],
  });

  useEffect(() => {
    if (scrollToComponent) {
      const element = document.getElementById(scrollToComponent);

      document.getElementById("container").scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0,
      });
      setScrollToComponent(null);
    }
  }, [scrollToComponent]);

  return (
    <div className={styles.faqContainer} id={faqId}>
      {!faq_page && <LoadingPage />}
      {faq_page && (
        <>
          <span className={textStyles.largeTitleDark}>
            {faq_page.faq_title}
          </span>
          {faq_page.faq_block.map((item, index) => (
            <div
              className={styles.questionLink}
              key={index.toString()}
              onClick={() => {
                setScrollToComponent(index.toString());
              }}
            >
              {RichText.asText(item.faq_question)}
            </div>
          ))}
          <div className={styles.divider}>&#8226; &#8226; &#8226;</div>
          {faq_page.faq_block.map((item, index) => (
            <div
              className={styles.faqBlock}
              key={index.toString()}
              id={index.toString()}
            >
              <span className={styles.question}>
                {RichText.asText(item.faq_question)}
              </span>
              <span className={styles.answer}>
                <PrismicText prismicText={item.faq_answer} />
              </span>
            </div>
          ))}
          <div
            className={styles.upIcon}
            onClick={() => {
              setScrollToComponent(faqId);
            }}
          >
            <img src="/icons/up-arrow.png" alt="Return to top" />
          </div>
        </>
      )}
    </div>
  );
};

export default FAQ;
