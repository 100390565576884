export const languageOptions = {
  en: {
    name: "English",
    value: "en-us",
  },
  es: {
    name: "Español",
    value: "es-mx",
  },
};

export const defaultLanguage = "en-us";
export const defaultLanguageSelectOption = { label: "English", value: "en-us" };
