import React, { useEffect, useState, useContext } from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import ConversationPreview from "components/userProfile/ConversationPreview/ConversationPreview";
import { UserContext } from "contexts/user";
import { docType } from "utils/prismic";
import { useParams } from "react-router-dom";
import {
  projectDatabase,
  usersPublicDir,
  usersPrivateDir,
} from "firebase/config";
import useTopScroll from "hooks/useTopScroll";

import styles from "./UserProfile.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const UserProfile = (props) => {
  useTopScroll();
  const { userId } = useParams();

  const { user_profile_page } = usePrismicContent({
    prismicDocTypes: [docType.user_profile_page],
  });

  const userContext = useContext(UserContext);

  const [userData, setUserData] = useState(null);
  const [userPrivData, setUserPrivData] = useState(null);
  const loadUser = (userId) => {
    const publicFacingQuery =
      !userContext.user || userContext.user.uid !== userId;

    let data = {};
    projectDatabase
      .ref(`/${usersPublicDir}/${userId}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          const pubData = snapshot.val();
          data = {
            ...data,
            ...pubData,
          };
        }
        setUserData(data);
      })
      .catch((error) => {
        console.error(
          `Error: could not find user with id ${userId} - ${error}`
        );
      });

    if (!publicFacingQuery) {
      projectDatabase
        .ref(`/${usersPrivateDir}/${userId}`)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            const privData = snapshot.val();
            setUserPrivData(privData);
          }
        });
    }
  };

  useEffect(() => {
    loadUser(userId);
  }, [userContext]);

  return (
    <div className={styles.container}>
      {(!user_profile_page || !userData) && <LoadingPage light />}

      {user_profile_page && userData && (
        <>
          <span className={textStyles.largeTitleLight}>
            {user_profile_page.conversations_title}
          </span>
          <div className={styles.userContent}>
            {userData &&
              Object.keys(userData).length === 0 &&
              !userPrivData && <span>No conversations</span>}

            {userData.acceptedConversations && (
              <>
                <span className={textStyles.midTitleLight}>
                  {user_profile_page.accepted_conversations}
                </span>
                {Object.keys(userData.acceptedConversations).map(
                  (conversationId, index) => {
                    const conversation =
                      userData.acceptedConversations[conversationId];
                    return (
                      <ConversationPreview
                        conversationData={conversation}
                        isOnMap
                      />
                    );
                  }
                )}
              </>
            )}

            {userPrivData && userPrivData.pendingConversations && (
              <>
                <span className={textStyles.midTitleLight}>
                  {user_profile_page.pending_conversations}
                </span>
                {Object.keys(userPrivData.pendingConversations).map(
                  (conversationId, index) => {
                    const conversation =
                      userPrivData.pendingConversations[conversationId];
                    return (
                      <ConversationPreview conversationData={conversation} />
                    );
                  }
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserProfile;
