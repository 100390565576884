import Prismic from "prismic-javascript";
import { firebaseAuth } from "firebase/config";
import { Link } from "react-router-dom";
import parser from "html-react-parser";

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
export const apiEndpoint =
  "https://26kclimateconversations.cdn.prismic.io/api/v2";

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  switch (doc.type) {
    case "map_page":
      return `/map`;
    case docType.faq_page:
      return "/faq";
    case docType.form_page:
      return "/form";
    case docType.form_success_page:
      return "/formSuccess";
    case docType.how_it_works_page:
      return "/how";
    case docType.privacy_policy:
      return "/legalStuff";
    case docType.sign_in_page:
      return "/signup";
    case docType.conversation_advice_page:
      return "/advice";
    case docType.how_to_page:
      return "/howTo";
    case docType.climate_knowledge_page:
      return "/knowledge";
    case docType.why_conversations_matter_page:
      return "/whyConversations";
    case docType.team_page:
      return "/team";
    case docType.contact_page:
      return "/contact";
    case docType.conversation_starters_page:
      return "/starters";
    case docType.language_advice_page:
      return "/language";
    case docType.user_profile_page:
      return "/user";
    case docType.landing_page:
    default:
      return "/";
  }
};

export const htmlSerializer = function (type, element, content, children) {
  switch (type) {
    // If user name, set that; else, set Link
    case "hyperlink":
      // A hacky way to get some semblance of variables with the headless CMS;
      // this adds the user's name instead of a link when the CMS provides a link to "user_name"
      if (element.data.type === "user_name") {
        const user = firebaseAuth.currentUser;

        if (!user) return null;
        return user.displayName ? user.displayName : user.email;
      } else if (element.data.type === "user_profile_page") {
        const user = firebaseAuth.currentUser;

        if (!user) return null;
        return (
          <Link
            to={`${linkResolver(element.data)}/${user.uid}`}
            target={"_self"}
            rel="noopener noreferrer"
          >
            {content}
          </Link>
        );
      }

      if (
        element.data.link_type === "Web" ||
        element.data.link_type === "Media"
      ) {
        return null;
      }

      return (
        <Link
          to={linkResolver(element.data)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content}
        </Link>
      );

    case "preformatted":
      return element["text"] ? parser(element["text"]) : null;

    // Return null to stick with the default behavior for all other elements
    default:
      return null;
  }
};

// Client method to query documents from the Prismic repo
export const client = Prismic.client(apiEndpoint);

// The current dictionary
export const prismicDictionary = {
  "en-us": {},
  "es-mx": {},
};

export const queryPrismicDocumentType = (language, docTypeId) => {
  return client.query(Prismic.Predicates.at("document.type", docTypeId), {
    lang: language, // en-us, es-mx
  });
};

export const queryMultiplePrismicDocumentTypes = (language, docTypeIds) => {
  return client.query(Prismic.Predicates.any("document.type", docTypeIds), {
    lang: language, // en-us, es-mx
    pageSize: 100,
  });
};

export const docType = {
  campaign_list: "campaign_list",
  conversation_popup: "conversation_popup",
  conversation_verification_page: "conversation_verification_page",
  cookie_banner: "cookie_banner",
  cta_buttons: "cta_buttons",
  faq_page: "faq_page",
  form_page: "form_page",
  form_questions: "form_questions",
  form_success_page: "form_success_page",
  how_it_works_page: "how_it_works_page",
  landing_page: "landing_page",
  navigation: "navigation",
  privacy_policy: "privacy_policy",
  share_message: "share_message",
  sign_in_page: "sign_in_page",
  conversation_advice_page: "conversation_advice_page",
  how_to_page: "how_to_page",
  climate_knowledge_page: "climate_knowledge_page",
  site_title: "site_title",
  relationships: "relationships",
  conversation_tags: "conversation_tags",
  user_profile_page: "user_profile_page",
  why_conversations_matter_page: "why_conversations_matter_page",
  team_page: "about_page", //"about" for legacy reasons
  contact_page: "contact_page",
  conversation_starters_page: "conversation_starters_page",
  language_advice_page: "language_advice_page",
  mailchimp_subscribe: "mailchimp_subscribe",
  partners_page: "partners_page",
  form_language_list: "form_language_list",
};
