import React, { useState } from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import useTopScroll from "hooks/useTopScroll";

import styles from "./PrivacyPolicy.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const PrivacyPolicy = () => {
  useTopScroll();
  const { privacy_policy } = usePrismicContent({
    prismicDocTypes: [docType.privacy_policy],
  });

  return (
    <div className={styles.ppContainer}>
      {!privacy_policy && <LoadingPage />}
      {privacy_policy && (
        <div className={styles.content}>
          <span className={textStyles.largeTitleDark}>
            {RichText.asText(privacy_policy.privacy_policy_title)}
          </span>
          <PrismicText prismicText={privacy_policy.privacy_policy_body} />
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicy;
