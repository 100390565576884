import React from "react";

import styles from "./ConversationPopup.module.css";

const ConversationPopupChoiceBlock = (props) => {
  const { preview, onClick } = props;
  return (
    <div className={styles.choiceBlock} onClick={onClick}>
      {/* {conversationData.photo && (
        <div className={styles.choiceBlockImage}></div>
      )} */}
      <div className={styles.choiceBlockTextContent}>
        {preview}
        {/* {tags && tags.length > 0 && (
          <div className={styles.contentRow}>
            {tags.map((tag, i) => {
              return (
                <span className={styles.tag} key={i}>
                  {tag}
                </span>
              );
            })}
          </div>
        )} */}
      </div>
      <div className={styles.rightArrow}>
        <img src="/icons/right-arrow.png" />
      </div>
    </div>
  );
};

export default ConversationPopupChoiceBlock;
