import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import ConversationPopup from "./ConversationPopup/ConversationPopup";

import styles from "./Map.module.css";
import { FeatureFlagContext } from "contexts/featureFlags/featureFlags";
import MapFilter from "components/MapFilter/MapFilter";
import useLoadAcceptedConversations from "hooks/useLoadAcceptedConversations";
import { usePrismicContent } from "components/PrismicText/PrismicText";
import { docType } from "utils/prismic";

const labelLayers = [
  "waterway-label",
  "natural-line-label",
  "natural-point-label",
  "water-line-label",
  "water-point-label",
  "settlement-minor-label",
  "settlement-major-label",
  "state-label",
  "country-label",
  "data-driven-circles-labels",
  "data-driven-circles",
];

mapboxgl.accessToken =
  "pk.eyJ1IjoiYXJpZWxsZWJyeW4iLCJhIjoiNGFlNzkyYjE5ZDhmMGExMzM0MGExY2UzYmRiODg1NTIifQ.kzP2s9mPRn2UjaCjIK5sUA";

const lng = 0;
const lat = 20;
const zoom = 2;

const Map = ({ isActive }) => {
  const [loadingConversationID, setLoadingConversationID] = useState(null);
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [mapOpacity, setMapOpacity] = useState(0);
  const mapContainer = React.createRef();
  let map = useRef(null);
  let popup = useRef(null);

  // Get specified campaign, if available
  let { campaign } = useParams();

  const { conversation_popup, relationships, campaign_list } =
    usePrismicContent({
      prismicDocTypes: [
        docType.conversation_popup,
        docType.relationships,
        docType.campaign_list,
      ],
    });
  const localizedDoc = conversation_popup
    ? { ...conversation_popup, relationships, campaign_list }
    : null;

  // Load in all accepted conversations
  const { acceptedConversations, finishedLoading: conversationsAreLoaded } =
    useLoadAcceptedConversations();

  // Get conversationId from url if available
  const { preloadedConversationId } = useParams();

  // If page has a preloaded conversation, try to load it
  useEffect(() => {
    if (!mapIsLoaded || !conversationsAreLoaded) {
      return;
    }

    if (isActive && preloadedConversationId) {
      loadConversationPopup(
        null,
        preloadedConversationId,
        lng,
        lat,
        (conversationId, conversationData) => {
          popup.current.setLngLat([
            conversationData.longitude,
            conversationData.latitude,
          ]);
          flyMapToConversationPopupLocation(
            conversationData.longitude,
            conversationData.latitude
          );
        }
      );
    }
  }, [preloadedConversationId, isActive, mapIsLoaded, conversationsAreLoaded]);

  const setExtraLayersVisible = (setVisible) => {
    if (!mapIsLoaded) {
      return;
    }

    for (const layer of labelLayers) {
      map.current.setLayoutProperty(
        layer,
        "visibility",
        setVisible ? "visible" : "none"
      );
    }

    const mapboxTopRightControl = document.getElementsByClassName(
      "mapboxgl-ctrl-top-right"
    )[0];
    if (mapboxTopRightControl) {
      mapboxTopRightControl.style.opacity = setVisible ? 100 : 0;
    }

    if (!setVisible && popup.current) {
      popup.current.remove();
    }
  };

  const flyMapToConversationPopupLocation = (longitude, latitude) => {
    const proportion =
      map.current._containerDimensions()[0] <= 800 ? 0.95 : 0.8;
    const shiftScreen =
      0.5 * proportion * map.current._containerDimensions()[1];
    const currentZoom = map.current.getZoom();
    map.current.flyTo({
      center: [longitude, latitude],
      offset: [0, shiftScreen],
      zoom: currentZoom <= 2 ? 3 : currentZoom,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  };

  const loadConversationPopup = (
    features,
    conversationId,
    longitude,
    latitude,
    onFinishLoad
  ) => {
    const popupNode = document.createElement("div");
    ReactDOM.render(
      <ConversationPopup
        features={features}
        preloadedConversationId={conversationId}
        onChooseConversationId={(conversationId) => {
          setLoadingConversationID(conversationId);
        }}
        onFinishLoad={(conversationId, conversationData) => {
          if (loadingConversationID === conversationId) {
            setLoadingConversationID(null);
          }

          if (onFinishLoad) {
            onFinishLoad(conversationId, conversationData);
          }
        }}
        localizedDoc={localizedDoc}
      />,
      popupNode
    );

    popup.current = new mapboxgl.Popup({
      maxWidth: "none",
      anchor: "bottom",
    })
      .setLngLat([longitude, latitude])
      .setDOMContent(popupNode)
      .addTo(map.current);
  };

  // Set up Map
  useEffect(() => {
    // Load conversation data before map
    if (!conversationsAreLoaded) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/ariellebryn/ckmfbsjzthfzr17o7kbl5bogm",
      center: [lng, lat],
      zoom,
      maxZoom: 12,
      minZoom: 2,
    });

    map.current.addControl(new mapboxgl.NavigationControl());

    map.current.on("style.load", () => {
      setMapIsLoaded(true);
      if (!isActive) {
        setExtraLayersVisible(false);
      }

      setMapOpacity(1);
    });

    map.current.on("click", "data-driven-circles", (e) => {
      if (!e || !e.features || !e.features[0]) {
        return;
      }

      if (
        e.features.length === 1 &&
        loadingConversationID === e.features[0].properties.uid
      ) {
        return;
      } else if (e.features.length > 1) {
        setLoadingConversationID(null);
      }

      flyMapToConversationPopupLocation(e.lngLat.lng, e.lngLat.lat);

      loadConversationPopup(e.features, null, e.lngLat.lng, e.lngLat.lat);
    });

    // change cursor to pointer when user hovers over a clickable feature
    map.current.on("mouseenter", "data-driven-circles", (e) => {
      if (e.features.length) {
        map.current.getCanvas().style.cursor = "pointer";
      }
    });

    // reset cursor to default when user is no longer hovering over a clickable feature
    map.current.on("mouseleave", "data-driven-circles", () => {
      map.current.getCanvas().style.cursor = "";
    });
  }, [conversationsAreLoaded]);
  setExtraLayersVisible(isActive);

  const setMapFilters = (filters) => {
    if (!mapIsLoaded) return;

    map.current.setFilter("data-driven-circles", ["all", ...filters]);
    map.current.setFilter("data-driven-circles-labels", ["all", ...filters]);
  };

  return (
    <>
      <div className="map_container">
        <div
          className="map"
          ref={mapContainer}
          style={{ opacity: mapOpacity }}
        />
      </div>
      {isActive && acceptedConversations && mapIsLoaded && (
        <MapFilter
          conversations={acceptedConversations}
          onSetFilters={setMapFilters}
          initialCampaign={campaign}
        />
      )}
    </>
  );
};

export default Map;
