import styles from "./MapFilter.module.css";
import { useState } from "react";
import { docType } from "utils/prismic";
import Select from "react-select";
import "./MapFilterTagger.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";
import { useEffect } from "react";

const ALL_CAMPAIGNS_OPTION = { label: "All Campaigns", value: undefined };

const MapFilter = ({ conversations, onSetFilters, initialCampaign }) => {
  const [filter, setFilter] = useState({
    campaign: undefined,
    tags: [],
  });

  // Get campaigns and tags options
  const { conversation_tags, campaign_list } = usePrismicContent({
    prismicDocTypes: [docType.conversation_tags, docType.campaign_list],
  });
  const campaigns = campaign_list
    ? campaign_list.campaign
        .map(({ campaign_id, name, include_in_map_filter }) => {
          const label = name[0].text;
          return { value: campaign_id, label, include: include_in_map_filter };
        })
        .filter((campaign) => campaign.include)
    : null;
  const tags = conversation_tags
    ? Object.keys(conversation_tags).map((tagId) => {
        return { label: conversation_tags[tagId], value: tagId };
      })
    : null;

  const buildMapboxFilters = ({ campaign, tags }) => {
    const filters = [];
    if (campaign) {
      filters.push(["==", ["get", "campaign"], campaign]);
    }
    if (tags.length > 0) {
      const filteredConversations = Object.keys(conversations).filter(
        (conversationId) => {
          const conversation = conversations[conversationId];
          return (
            conversation.tags &&
            conversation.tags.some((tag) => tags.includes(tag))
          );
        }
      );

      // You can't send an empty array to the match filter,
      // so if there are no matches, add something unmatchable to the array
      if (filteredConversations.length === 0) {
        filteredConversations.push("empty");
      }

      filters.push([
        "match",
        ["get", "uid"],
        filteredConversations,
        true,
        false,
      ]);
    }
    return filters;
  };

  const setCampaign = (newCampaign) => {
    setFilter({ ...filter, campaign: newCampaign });
    onSetFilters(
      buildMapboxFilters({ campaign: newCampaign, tags: filter.tags })
    );
  };

  const setSelectedTags = (newTags) => {
    const simplifiedTags = newTags.map((t) => t.value);
    setFilter({ ...filter, tags: simplifiedTags });
    onSetFilters(
      buildMapboxFilters({
        campaign: filter.campaign,
        tags: simplifiedTags,
      })
    );
  };

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused
        ? "rgba(0, 0, 0, 0.06)"
        : isSelected
        ? "rgba(0, 0, 0, 0.2)"
        : "transparent",
      color: "hsl(0, 0%, 20%)",
    }),
    indicatorSeparator: () => {
      "none";
    },
    control: (provided) => ({
      ...provided,
      border: 0,
      backgroundColor: "transparent",
      borderRadius: "8px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: "100px",
      overflowY: "scroll",
    }),
  };

  // Set initial campaign in map filter
  let initialCampaignSelectOption = campaigns.find(
    (campaign) => campaign.value === initialCampaign
  );
  if (!initialCampaignSelectOption) {
    initialCampaignSelectOption = ALL_CAMPAIGNS_OPTION;
  }
  useEffect(() => {
    if (initialCampaignSelectOption) {
      setCampaign(initialCampaignSelectOption.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCampaign]);

  return (
    <div className={styles.filterContainer}>
      <div className={styles.filterTitle}>
        <b>Filter</b>
      </div>
      <div className={styles.filterSeparator} />
      <div className={styles.filterCampaign}>
        <Select
          defaultValue={initialCampaignSelectOption}
          name="campaign"
          options={[ALL_CAMPAIGNS_OPTION, ...campaigns]}
          className={styles.filterElement}
          classNamePrefix="mapFilterSelect"
          openMenuOnFocus
          onChange={(campaign) => {
            setCampaign(campaign.value);
          }}
          isSearchable={false}
          styles={customStyles}
        />
      </div>

      <div className={styles.filterSeparator} />
      <div className={styles.filterTags}>
        <Select
          defaultValue={[]}
          isMulti
          name="tags"
          options={tags}
          className={styles.filterElement}
          classNamePrefix="mapFilterSelect"
          openMenuOnFocus
          onChange={(newTags) => setSelectedTags(newTags)}
          placeholder="Tags"
          styles={customStyles}
          isSearchable
        />
      </div>
    </div>
  );
};

export default MapFilter;
