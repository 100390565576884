import React, { useState } from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import useTopScroll from "hooks/useTopScroll";

import styles from "./Partners.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const Partners = () => {
  useTopScroll();
  const { partners_page } = usePrismicContent({
    prismicDocTypes: [docType.partners_page],
  });

  return (
    <div className={styles.container}>
      {!partners_page && <LoadingPage />}
      {partners_page && (
        <div className={styles.content}>
          <span className={textStyles.largeTitleDark}>
            {RichText.asText(partners_page.title)}
          </span>
          <PrismicText prismicText={partners_page.intro} />

          <div className={styles.partners}>
            {partners_page.partner_logos.map((block) => {
              return (
                <div className={styles.partnerBlock}>
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${
                        block.image && block.image.url ? block.image.url : ""
                      })`,
                    }}
                  />
                </div>
              );
            })}
          </div>

          <PrismicText prismicText={partners_page.outro} />
        </div>
      )}
    </div>
  );
};

export default Partners;
