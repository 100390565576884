import React, { useState } from "react";

import styles from "./ApprovalDateInput.module.css";

const getXDaysAgo = (numDays) => {
  // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
  return new Date() - 1000 * 60 * 60 * 24 * numDays;
};

const ApprovalDateInput = ({ onSubmit }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className={styles.dateForm}>
      <div className={styles.dateInput}>
        <label htmlFor="start">Start date:</label>
        <input
          type="datetime-local"
          id="start"
          name="start"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </div>
      <div className={styles.dateInput}>
        <label htmlFor="end">End date:</label>
        <input
          type="datetime-local"
          id="end"
          name="end"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <div
          className={styles.search}
          onClick={() => onSubmit(Date.parse(startDate), Date.parse(endDate))}
        >
          Search
        </div>
      </div>
    </div>
  );
};

export default ApprovalDateInput;
