import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "contexts/user";
import ApprovalBlock from "components/approval/ApprovalBlock/ApprovalBlock";
import { Link } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import {
  projectDatabase,
  pendingConversationsDir,
  acceptedConversationsDir,
} from "firebase/config";
import useTopScroll from "hooks/useTopScroll";
import { functionDomain } from "config";
import ApprovalDateInput from "components/approval/ApprovalDateInput/ApprovalDateInput";

import axios from "axios";

import styles from "./Approval.module.css";
import textStyles from "style/TextStyle.module.css";

const Approval = (props) => {
  useTopScroll();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [pendingConversations, setPendingConversations] = useState([]);
  const [lastKey, setLastKey] = useState(null);
  const userContext = useContext(UserContext);

  const requestWithAuthorizationHeader = (request) => {
    setLoading(true);

    if (!userContext.user) {
      setLoading(false);
      setAuthorized(false);
      return;
    }

    userContext.user
      .getIdToken()
      .then((idToken) => {
        const options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        };
        request(options);
      })
      .catch((error) => {
        console.error(`Error retrieving id token of user:  ${error}`);
        setLoading(false);
        setAuthorized(false);
      });
  };

  const addMapboxFeature = (conversationData) => {
    requestWithAuthorizationHeader((options) => {
      const url = `${functionDomain}/mapFeature/${conversationData.conversationId}`;
      axios
        .post(url, conversationData, options)
        .then((response) => {})
        .catch((error) => {
          console.error("Error adding mapbox feature: " + error);
        });
    });
  };

  const removePendingConversation = (index) => {
    const newConversations = [...pendingConversations];
    newConversations.splice(index, 1);
    setPendingConversations(newConversations);
  };

  const onApproveConversation = (conversationID, index, onFinish) => {
    requestWithAuthorizationHeader((options) => {
      axios
        .post(
          `${functionDomain}/acceptedConversations/${conversationID}`,
          null,
          options
        )
        .then((response) => {
          addMapboxFeature(pendingConversations[index][1]);
          removePendingConversation(index);
          setLoading(false);
          onFinish();
        })
        .catch((error) => {
          console.error(
            `ERROR ACCEPTING CONVERSATION WITH ID ${conversationID}: ` + error
          );
          onFinish();
        });
    });
  };

  const onRejectConversation = (
    conversationID,
    photoPath,
    userId,
    email,
    index,
    onFinish
  ) => {
    requestWithAuthorizationHeader((options) => {
      options.data = {
        photoPath: photoPath,
        userId: userId,
        email: email,
      };
      axios
        .delete(
          `${functionDomain}/pendingConversations/${conversationID}`,
          options
        )
        .then((response) => {
          removePendingConversation(index);
          onFinish();
        })
        .catch((error) => {
          console.error(
            `ERROR REMOVING CONVERSATION WITH ID ${conversationID}: ` + error
          );
          onFinish();
        });
    });
  };

  const loadPendingConversations = (startDate, endDate, limit) => {
    let ref = projectDatabase
      .ref(pendingConversationsDir)
      .orderByChild("createdAt");

    if (lastKey) {
      ref = ref.startAt(parseInt(lastKey));
    } else if (startDate) {
      ref = ref.startAt(parseInt(startDate));
    }

    if (endDate) {
      ref = ref.endAt(parseInt(endDate));
    }

    ref
      .limitToFirst(limit ? limit : 5)
      .once("value")
      .then((snapshot) => {
        setAuthorized(true);
        if (snapshot.exists()) {
          const data = snapshot.val();

          let newDataArr = Object.keys(data).map((key) => [key, data[key]]);

          newDataArr = newDataArr.filter(function (el) {
            for (let i = 0; i < pendingConversations.length; i++) {
              if (pendingConversations[i][0] === el[0]) {
                return false;
              }
            }
            return true;
          });

          const maybeNew = [...pendingConversations, ...newDataArr];

          setPendingConversations(maybeNew);
          setLoading(false);

          const length = Object.values(data).length;
          if (length <= 0) {
            return;
          }

          setLastKey(Object.values(data)[length - 1].createdAt);
        } else {
          console.log("No data available");
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.error("Error reading data from database: " + error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!userContext.user) {
      setAuthorized(false);
      return;
    }

    userContext.user.getIdTokenResult().then((idTokenResult) => {
      if (!idTokenResult.claims || !idTokenResult.claims.admin) {
        setLoading(false);
        setAuthorized(false);
        return;
      }

      setPendingConversations([]);
      loadPendingConversations();
    });
  }, [userContext]); // Do not delete this [], it stops this block from continuously executing

  // const deleteExpiredUnverifiedConversations = () => {
  //   const options = {
  //     headers: {
  //       "Content-Type": "text/plain;charset=utf-8",
  //     },
  //   };
  //   axios
  //     .delete(
  //       `${functionDomain}/deleteExpiredUnverifiedConversations`,
  //       options
  //     )
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const [isDeleteToolActive, setIsDeleteToolActive] = useState(false);
  const [conversationIdToDelete, setConversationIdToDelete] = useState(null);
  const [conversationDataToDelete, setConversationDataToDelete] = useState(
    null
  );
  const [
    deleteConversationToolMessage,
    setDeleteConversationToolMessage,
  ] = useState(null);
  const findConversation = (e) => {
    projectDatabase
      .ref(`/${acceptedConversationsDir}/${conversationIdToDelete}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setConversationDataToDelete(data);
        } else {
          setDeleteConversationToolMessage("Conversation not found");
        }
      })
      .catch((error) => {
        console.error(
          `Error: could not find conversation with id ${conversationIdToDelete}... ${error}`
        );
        setDeleteConversationToolMessage("Conversation not found");
      });

    e.preventDefault();
  };

  const deleteConversation = (conversationId, conversationData) => {
    requestWithAuthorizationHeader((options) => {
      options.data = {
        photoPath: conversationData.photoPath,
      };
      axios
        .delete(
          `${functionDomain}/acceptedConversations/${conversationIdToDelete}`,
          options
        )
        .then((response) => {
          setDeleteConversationToolMessage("Conversation deleted");
          setConversationDataToDelete(null);
        })
        .catch((error) => {
          setDeleteConversationToolMessage("Could not delete conversation");
          console.error(
            `ERROR DELETING CONVERSATION WITH ID ${conversationIdToDelete}: ` +
              error
          );
        });
    });
  };

  const [isCreateAdminToolActive, setCreateAdminToolActive] = useState(false);
  const [emailToMakeAdmin, setEmailToMakeAdmin] = useState(null);
  const [createAdminToolMessage, setCreateAdminToolMessage] = useState(null);
  const setAdmin = (e) => {
    e.preventDefault();
    requestWithAuthorizationHeader((options) => {
      axios
        .post(
          `${functionDomain}/admin`,
          {
            email: emailToMakeAdmin,
          },
          options
        )
        .then((response) => {
          setCreateAdminToolMessage("User is now an admin");
          setCreateAdminToolMessage(null);
        })
        .catch((error) => {
          setCreateAdminToolMessage(
            "Could not make this user an admin (check log for details)"
          );
          console.error(`Couldn't make user  an admin: ` + error);
        });
    });
  };

  const onSearchByDate = (startDate, endDate) => {
    setPendingConversations([]);
    setLoading(true);
    setLastKey(null);
    loadPendingConversations(startDate, endDate, 10);
  };

  return (
    <div className={styles.container}>
      {/* <button onClick={deleteExpiredUnverifiedConversations}>
        Delete expired unverifieds
      </button> */}
      <>
        <span className={textStyles.largeTitleDark}>Admin Approval</span>
        {authorized && (
          <div className={styles.clickOpen}>
            <div
              className={styles.clickOpenHeader}
              onClick={() => setIsDeleteToolActive(!isDeleteToolActive)}
            >
              Delete accepted conversations tool
            </div>
            <div
              className={`${styles.dropdownBody} ${
                isDeleteToolActive ? styles.active : styles.inactive
              }`}
            >
              <form onSubmit={findConversation} className={styles.form}>
                <label htmlFor="conversationId">
                  Id of conversation you want to delete
                </label>
                <input
                  name="conversationId"
                  type="text"
                  onChange={(e) => setConversationIdToDelete(e.target.value)}
                />
                <button type="submit" className={styles.submitButton}>
                  Find conversation
                </button>
              </form>
              {deleteConversationToolMessage}
              {conversationDataToDelete && (
                <div className={styles.conversationToDelete}>
                  {conversationDataToDelete.description}
                  <div
                    className={styles.confirmDelete}
                    onClick={() =>
                      deleteConversation(
                        conversationDataToDelete.conversationId,
                        conversationDataToDelete
                      )
                    }
                  >
                    Delete conversation
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {authorized && (
          <div className={styles.clickOpen}>
            <div
              className={styles.clickOpenHeader}
              onClick={() => setCreateAdminToolActive(!isCreateAdminToolActive)}
            >
              Add user as admin
            </div>
            <div
              className={`${styles.dropdownBody} ${
                isCreateAdminToolActive ? styles.active : styles.inactive
              }`}
            >
              <form onSubmit={setAdmin} className={styles.form}>
                <label htmlFor="email">
                  Email of user you want to make admin
                </label>
                <input
                  name="email"
                  type="text"
                  onChange={(e) => setEmailToMakeAdmin(e.target.value)}
                />
                <button type="submit" className={styles.submitButton}>
                  Set user
                </button>
              </form>
              {createAdminToolMessage}
            </div>
          </div>
        )}

        <div className={styles.approvalTool}>
          {authorized && <h4>Approval Tool</h4>}
          {authorized && <ApprovalDateInput onSubmit={onSearchByDate} />}
          {authorized &&
            pendingConversations &&
            Object.entries(pendingConversations).map(
              (conversationData, index) => (
                <ApprovalBlock
                  key={index.toString()}
                  conversationData={conversationData[1]}
                  onClickReject={(
                    conversationID,
                    photoPath,
                    userId,
                    email,
                    onFinish
                  ) =>
                    onRejectConversation(
                      conversationID,
                      photoPath,
                      userId,
                      email,
                      index,
                      onFinish
                    )
                  }
                  onClickApprove={(conversationID, onFinish) => {
                    onApproveConversation(conversationID, index, onFinish);
                  }}
                />
              )
            )}

          {authorized &&
            (!pendingConversations || pendingConversations.length === 0) && (
              <p>No conversations available</p>
            )}

          {!loading && authorized && (
            <div
              className={styles.loadMoreButton}
              onClick={loadPendingConversations}
            >
              Load More
            </div>
          )}
        </div>

        {loading && <LoadingSpinner />}

        {!loading && !authorized && (
          <>
            <span>
              You are not authorized to view this page. Please{" "}
              <u>
                <Link to="/signup">sign in</Link>
              </u>{" "}
              with an authorized account.
            </span>
          </>
        )}
      </>
    </div>
  );
};

export default Approval;
