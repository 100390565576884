import React from "react";
import HowItWorksBlock from "components/HowItWorksBlock/HowItWorksBlock";
import CTA from "components/CTA/CTA";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import LoadingPage from "components/LoadingPage/LoadingPage";
import useTopScroll from "hooks/useTopScroll";
import MailchimpSubscribeForm from "components/MailchimpSubscribeForm/MailchimpSubscribeForm";

import styles from "./HowItWorks.module.css";
import PrismicText from "components/PrismicText/PrismicText";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const HowItWorks = () => {
  useTopScroll();
  const { how_it_works_page } = usePrismicContent({
    prismicDocTypes: [docType.how_it_works_page],
  });

  return (
    <div className={styles.howContainer}>
      {!how_it_works_page && (
        <div className={styles.loadingContainer}>
          <LoadingPage />
        </div>
      )}
      {how_it_works_page && (
        <div className={styles.howItWorks}>
          <div className={styles.howItWorksTitle}>
            <span>{RichText.asText(how_it_works_page.how_it_works_title)}</span>
          </div>

          <div className={styles.intro}>
            <PrismicText prismicText={how_it_works_page.intro} />
          </div>

          <div className={styles.howContent}>
            <div className={styles.howRow}>
              <HowItWorksBlock
                highlightDoc={how_it_works_page.how_it_works_blocks[0].step}
                titleDoc={how_it_works_page.how_it_works_blocks[0].title}
                paragraphDocs={how_it_works_page.how_it_works_blocks[0].body}
              />

              <div className={styles.howExampleContainer}>
                <span>
                  <PrismicText
                    prismicText={how_it_works_page.reach_out_help_title}
                  />
                </span>
                <div className={styles.howExample}>
                  <PrismicText
                    prismicText={how_it_works_page.reach_out_help_example}
                  />
                  <div className={styles.howExampleTip}></div>
                </div>
              </div>
            </div>
            <div className={styles.howRow} style={{ alignItems: "center" }}>
              <div className={styles.howImage}></div>
              <HowItWorksBlock
                highlightDoc={how_it_works_page.how_it_works_blocks[1].step}
                titleDoc={how_it_works_page.how_it_works_blocks[1].title}
                paragraphDocs={how_it_works_page.how_it_works_blocks[1].body}
              />
            </div>
            <div
              className={`${styles.howRow} ${styles.noMargin}`}
              style={{ alignItems: "center" }}
            >
              <HowItWorksBlock
                highlightDoc={how_it_works_page.how_it_works_blocks[2].step}
                titleDoc={how_it_works_page.how_it_works_blocks[2].title}
                paragraphDocs={how_it_works_page.how_it_works_blocks[2].body}
              >
                <div style={{ marginTop: "40px" }}>
                  <CTA />
                </div>
              </HowItWorksBlock>
            </div>
            <div className={styles.mailchimp}>
              <MailchimpSubscribeForm />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HowItWorks;
