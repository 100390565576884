import React, { useState } from "react";

import LoadingPage from "components/LoadingPage/LoadingPage";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import useTopScroll from "hooks/useTopScroll";

import styles from "./WhyConversations.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const WhyConversations = (props) => {
  useTopScroll();

  const { why_conversations_matter_page } = usePrismicContent({
    prismicDocTypes: [docType.why_conversations_matter_page],
  });

  return (
    <div className={styles.outer}>
      {why_conversations_matter_page && (
        <img
          className={styles.banner}
          src={why_conversations_matter_page.image.url}
          alt={why_conversations_matter_page.image.alt}
        />
      )}
      <div className={styles.container}>
        {!why_conversations_matter_page && <LoadingPage />}
        {why_conversations_matter_page && (
          <div className={styles.content}>
            <span className={textStyles.largeTitleDark}>
              {RichText.asText(why_conversations_matter_page.title)}
            </span>
            <PrismicText prismicText={why_conversations_matter_page.body} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WhyConversations;
