import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CTA from "components/CTA/CTA";
import LandingConversation from "components/landing/LandingConversation/LandingConversation";
import { mediaQueryListener } from "utils/media-query";

import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";

import styles from "./Landing.module.css";

import content from "assets/text/content.json";
import LoadingPage from "components/LoadingPage/LoadingPage";
import { usePrismicContent } from "components/PrismicText/PrismicText";
import PrismicText from "components/PrismicText/PrismicText";

const singleColumnMaxWidth = 1000;

const Landing = ({ campaign = "default" }) => {
  const [isSingleColumn, setIsSingleColumn] = useState(
    window.innerWidth < singleColumnMaxWidth
  );

  const { landing_page } = usePrismicContent({
    prismicDocTypes: [docType.landing_page],
    campaign,
  });

  useEffect(() => {
    return mediaQueryListener(singleColumnMaxWidth, (isUnderMaxWidth) => {
      setIsSingleColumn(isUnderMaxWidth);
    });
  }, []);

  const useBlackKelsonTheme = campaign === "fashionrevolution";

  return (
    <div className={styles.landingContainer} id="landing">
      <div className={styles.landingBg} />
      <div className={styles.landingSection}>
        {!landing_page && (
          <div className={styles.loadingContainer}>
            <LoadingPage />
          </div>
        )}
        {landing_page && (
          <div className={styles.landing}>
            <div className={styles.landingBlock}>
              <div
                className={`${styles.landingInformation} ${
                  useBlackKelsonTheme ? styles.kelson : ""
                }`}
              >
                <div
                  className={`${styles.title} ${
                    useBlackKelsonTheme ? styles.kelson_title : ""
                  }`}
                >
                  {RichText.asText(landing_page.landing_title)}
                </div>
                <PrismicText prismicText={landing_page.body} />

                <div className={styles.howItWorksLink}>
                  <Link
                    to={{
                      pathname: content.navigation.how.pathname,
                    }}
                  >
                    {RichText.asText(landing_page.extra_cta_link)}
                    <img src="/icons/right-arrow.png" alt="Read how it works" />
                  </Link>
                </div>
                <CTA
                  submitConversation
                  seeTheMap
                  campaign={campaign}
                  theme={useBlackKelsonTheme ? true : false}
                />
              </div>
            </div>
            {!isSingleColumn && (
              <div className={styles.landingBlock}>
                <LandingConversation
                  localizedDoc={landing_page}
                  theme={useBlackKelsonTheme ? true : false}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Landing;
