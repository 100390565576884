import React, { useState } from "react";
// import {
//   TwitterShareButton,
//   FacebookShareButton,
// } from "components/SocialShareButtons/SocialShareButtons";
import LoadingPage from "components/LoadingPage/LoadingPage";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import CTA from "components/CTA/CTA";
import { functionDomain } from "config";

import axios from "axios";
import { RichText } from "prismic-reactjs";
import { docType } from "utils/prismic";
import PrismicText from "components/PrismicText/PrismicText";
import { useParams } from "react-router-dom";

import styles from "./Form.module.css";
import textStyles from "style/TextStyle.module.css";
import { usePrismicContent } from "components/PrismicText/PrismicText";

const FormSuccess = () => {
  const { form_success_page, share_message } = usePrismicContent({
    prismicDocTypes: [docType.form_success_page, docType.share_message],
  });

  const [resendLoading, setResendLoading] = useState(false);

  let { verificationId } = useParams();
  let email = verificationId ? verificationId.split("__")[0] : "";

  const reattemptEmail = () => {
    if (!verificationId) {
      console.error("Error: No verificationId provided");
      return;
    }

    setResendLoading(true);

    const options = {
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    };
    axios
      .post(
        `${functionDomain}/conversationEmail`,
        {
          verificationId: verificationId,
        },
        options
      )
      .then((response) => {
        setResendLoading(false);
      })
      .catch((error) => {
        console.error(`Error: couldn't resend email - ${error}`);
      });
  };

  return (
    <div className={styles.formPage}>
      {!form_success_page && !share_message && <LoadingPage />}
      {form_success_page && share_message && (
        <div className={styles.formSuccess}>
          <span
            className={`${textStyles.largeTitleDark} ${styles.formIntroTitle}`}
          >
            {RichText.asText(form_success_page.form_success_title)}
          </span>
          <span className={`${textStyles.textContent} ${styles.formIntroText}`}>
            <PrismicText prismicText={form_success_page.form_success_body} />
          </span>
          {verificationId && (
            <>
              <span
                className={`${textStyles.textContent} ${styles.formIntroText}`}
              >
                <PrismicText
                  prismicText={form_success_page.form_success_unverified}
                />
              </span>
              <span
                className={`${textStyles.textContent} ${styles.formIntroText} ${styles.formSuccessEmailReattempt}`}
                onClick={reattemptEmail}
              >
                <PrismicText
                  prismicText={
                    form_success_page.form_success_unverified_resend_button
                  }
                />{" "}
                {email}.
              </span>
              {resendLoading && <LoadingSpinner />}
            </>
          )}
          {!verificationId && (
            <span
              className={`${textStyles.textContent} ${styles.formIntroText}`}
            >
              <PrismicText
                prismicText={form_success_page.form_success_verified}
              />
            </span>
          )}

          {/* TODO: Put back social share buttons */}
          {/* <div className={styles.share}>
            <span className={styles.shareLabel}>
              <PrismicText prismicText={form_success_page.form_success_share} />
            </span>
            <div className={styles.shareButton}>
              <TwitterShareButton
                shareMessage={RichText.asText(
                  share_message.twitter_share_message
                )}
                dark
              />
            </div>
            <div className={styles.shareButton}>
              <FacebookShareButton
                shareMessage={RichText.asText(
                  share_message.facebook_share_message
                )}
                dark
              />
            </div>
          </div> */}

          <div className={styles.cta}>
            <CTA submitAnotherConversation seeTheMap />
          </div>
        </div>
      )}
    </div>
  );
};

export default FormSuccess;
