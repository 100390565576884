import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "components/header/Navigation";
import MenuButton from "components/header/MenuButton/MenuButton";
import MobileNav from "components/header/MobileNav/MobileNav";
import { projectDatabase, metadataDir } from "firebase/config";

import styles from "./Header.module.css";

const Header = (props) => {
  const { isMobile, onClickMobileMenu } = props;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [numConversations, setNumConversations] = useState(-1);

  useEffect(() => {
    projectDatabase
      .ref(`/${metadataDir}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          setNumConversations(snapshot.val().conversationCount);
        }
      })
      .catch((error) => {
        console.error(`Error: could not find metadata`);
        setNumConversations(0);
      });
  }, []);

  const toggleMobileMenu = () => {
    // Call callback
    onClickMobileMenu(!isMobileMenuOpen);

    // Toggle
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.getElementById("container").scrollTo({
        behavior: "auto",
        top: 0,
      });
    }
  }, [isMobileMenuOpen]);

  const nav = !isMobile ? (
    <Navigation
      navigationItemClass="header_tab"
      navigationSubListClass="header_dropdown"
      languagePickerClass="header_tab"
    />
  ) : (
    <MenuButton onClick={toggleMobileMenu} showCloseButton={isMobileMenuOpen} />
  );

  const menu = isMobile ? (
    <MobileNav
      isActive={isMobileMenuOpen}
      onMobileLinkClick={toggleMobileMenu}
    />
  ) : null;

  return (
    <>
      <div className="header">
        <div className={styles.leftBlock}>
          <Link to="/">
            <div className={styles.logo}>
              <img
                src="/images/logo_placeholder_angle.png"
                alt="Talk Climate Change Logo"
              />
            </div>
          </Link>
        </div>
        <div className="header_nav">{nav}</div>
      </div>
      {menu}
    </>
  );
};

export default Header;
