import React from "react";
import Navigation from "components/header/Navigation";

import styles from "./MobileNav.module.css";

const MobileNav = (props) => {
  const { isActive, onMobileLinkClick } = props;
  return (
    <div className={`${styles.mobileMenu} ${isActive ? styles.active : ""}`}>
      <Navigation
        navigationItemClass={styles.navigationItem}
        navigationSubListClass={styles.navigationSubList}
        languagePickerClass={styles.languagePicker}
        onLinkClick={onMobileLinkClick}
      />
    </div>
  );
};

export default MobileNav;
