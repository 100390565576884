import React, { useState, useRef, useEffect } from "react";
// import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// import mapboxgl from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"
import Geocoder from "react-mapbox-gl-geocoder";
import ReactMapGL, { Marker } from "react-map-gl";
import styles from "./FormLocationPicker.module.css";

const mapAccess = {
  mapboxApiAccessToken:
    "pk.eyJ1IjoiYXJpZWxsZWJyeW4iLCJhIjoiNGFlNzkyYjE5ZDhmMGExMzM0MGExY2UzYmRiODg1NTIifQ.kzP2s9mPRn2UjaCjIK5sUA",
};

const mapStyle = {
  width: "100%",
  height: "100%",
};

const getGeocoderInputElement = () => {
  return document
    .getElementsByClassName("react-geocoder")[0]
    .getElementsByTagName("INPUT")[0];
};

const FormLocationPicker = (props) => {
  const { placeholderLocation, onChange, onBlur, onSetLocation } = props;
  const [viewport, setViewport] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  let map = useRef(null);

  const onSelected = (viewport, item) => {
    const location = {
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      ...item,
    };
    setViewport(viewport);
    setSelectedLocation([viewport.latitude, viewport.longitude]);
    onSetLocation(location);
  };

  useEffect(() => {
    const input = getGeocoderInputElement();
    input.placeholder = placeholderLocation;
    input.setAttribute("name", "location");
    input.setAttribute("id", "location");
    input.addEventListener("change", onChange);
    input.addEventListener("blur", onBlur);
  }, [onChange, onBlur, placeholderLocation]);

  return (
    <div className={styles.formLocationPicker}>
      <div className={styles.geocoder}>
        <Geocoder
          mapRef={map}
          {...mapAccess}
          onSelected={onSelected}
          viewport={viewport}
          hideOnSelect={true}
          updateInputOnSelect={true}
        />
      </div>
      <div className={styles.map}>
        <ReactMapGL
          mapStyle="mapbox://styles/mapbox/streets-v11"
          {...mapAccess}
          {...viewport}
          {...mapStyle}
          onViewportChange={(newViewport) => setViewport(newViewport)}
        >
          {selectedLocation && (
            <Marker
              longitude={selectedLocation[1]}
              latitude={selectedLocation[0]}
            >
              <div className={styles.marker}>
                <span></span>
              </div>
            </Marker>
          )}
        </ReactMapGL>
      </div>
    </div>
  );
};

export default FormLocationPicker;
